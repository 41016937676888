<template>
    <div class="my-3">
        <img class="max-w-xs" v-if="isSquare" v-bind:class="{ 'w-32': this.smallWidth, 'h-32': this.smallHeight}" :src="squareLogoUrl" />
        <img v-if="!isSquare" v-bind:class="{ 'w-32': this.smallWidth, 'h-32': this.smallHeight}" :src="circleLogoUrl" :style="style" class="border-game-blue rounded-full"/>
    </div>
</template>

<script>

export default {
  name: 'SponsorLogo',
  props: ['sponsorId', 'isSquare', 'smallHeight', 'smallWidth', 'selectedSponsorId'],
  inject: ['sponsors'],
  components: {
  },
  computed: {
      selected() {
        return this.selectedSponsorId == this.sponsorId
      },
      style() {
          return { 'border-width': this.selected ? '8px' : '0px' }
      },
      squareLogoUrl() {
                return require('@/images/SquareLogo/' + this.logoName + '.png')
            },
            
            circleLogoUrl() {
                return require('@/images/CircleLogo/' + this.logoName + '.png')
            },

            sponsor() {
                const findSponsorId = this.sponsorId
                return this.sponsors.find(sponsor => sponsor.id == findSponsorId)
            },

            logoName() {
                if (!this.sponsor) {
                    console.error(`Failed to find sponsor for id: ${this.sponsorId}`)
                }
                return this.sponsor.logoName
            },
  },
  methods: {
  },
}
</script>