<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <section class="bg-gradient-to-b from-gray-100 to-white">
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <div class="pt-32 pb-12 md:pt-40 md:pb-20">

            <!-- Page header -->
            <div class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
              <h1 class="h1 mb-4">Check your email!</h1>
              <p class="text-xl text-gray-600">We sent you an email with the sign in link.</p>
              <p class="text-xl font-bold text-gray-600">If you don’t receive it within 15 minutes, request another sign in link.</p>
            </div>

            <!-- Form -->
            <div class="max-w-sm mx-auto">
              <form>
                <div class="flex flex-wrap -mx-3 mb-4">
                  <div class="w-full px-3">
                    <label class="block text-gray-800 text-sm font-medium mb-1" for="email">Email <span class="text-red-600">*</span></label>
                    <input id="email" type="email" v-model.trim="emailAddress" class="form-input w-full text-gray-800" placeholder="jdoe@att.com" required />
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mt-6">
                  <div class="w-full px-3">
                    <button class="btn text-white bg-blue-600 hover:bg-blue-700 w-full">Resend magic link</button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </section>

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import { auth } from './../firebase';

export default {
  name: 'ResetPassword',
  components: {
    Header,
  },
  data() {
    return {
      emailAddress: window.localStorage.getItem('emailForSignIn') || '',
    };
  },
  methods: {
    sendMagicLink() {
      const actionCodeSettings = {
        url: window.location.origin + '/finish-sign-in',
        handleCodeInApp: true,
      };
      auth.sendSignInLinkToEmail(this.emailAddress, actionCodeSettings)
        .then(() => {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            console.log(`Sign in link sent successfully`);
            window.localStorage.setItem('emailForSignIn', this.emailAddress);
        })
        .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.error(`Failed to send link. Code: ${errorCode}. Details: ${errorMessage}`);
        });
    }
  }
}
</script>
