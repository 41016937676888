<template>
<section>
    <div class="max-w-3xl mx-auto px-4 sm:px-6">
        <div class="py-12 md:py-20 text-center">
            <div class="relative flex justify-center mb-8">
                <div class="flex flex-col justify-center">
                    <h1 v-if="!isVendor" class="h1 py-12">Welcome, AT&amp;T Broadband and Video Employee!</h1>
                    <h1 v-if="isVendor" class="h1 py-12">Welcome, Programmer</h1>
                    <img class="mx-auto my-4 w-48 h-48 rounded-full" :src="require('@/images/game/avatar/Avatar_Teal.svg')">
                    
                    <p v-if="nameOrCompany" class="mt-1 py-1">{{ nameOrCompany }}</p>
                    <p v-if="isVendor" class="py-1 text-sm text-blue-600">Programmer</p>
                    <p v-if="!isVendor" class="py-1 text-sm text-blue-600">Player</p>
                </div>
            </div>
        </div>
    </div>
</section>          
</template>

<script>
import { playerCollection, programmerCollection } from '../firebase.js'

export default {
  name: 'AccountHero',
  props: ['sponsorId'],

  components: {
    // Modal,
  },
  computed: {
      nameOrCompany() {
          if (this.isVendor) {
              // query for company
              return this.companyName
          }
          return this.currentUser.email || ''
      },
      isVendor() {
          if (this.userDocument) {
              return this.userDocument.programmer
          }
          return false
      }
  },
  data() {
      return { userDocument: null, companyName: '' }
  },
  inject: ['currentUser'],
  mounted() {
    //   console.dir(this.currentUser.email)
      playerCollection.doc(this.currentUser.email).get().then(snapshot => {
          console.log(snapshot)
          if (snapshot.exists) {
              this.userDocument = snapshot.data()
          }
      })
  },
  watch: {
      sponsorId(newSponsorId) {
          programmerCollection.doc(newSponsorId).get()
            .then((programmerSnap) => {
                if (programmerSnap.exists) {
                    const programmerData = programmerSnap.data()
                    this.companyName = programmerData.displayName
                }
            })
      }
  },
}
</script>