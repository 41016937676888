<template>
  <GameModal :showModal="showModal" :dismissFunc="onDismiss" :modalBackgroundUrl="require('@/images/game/ui/bg-mini-game.svg')">
    <template v-slot:logo>
      <div class="flex flex-row justify-center mt-10">
        <SponsorLogo :sponsorId="leftSponsor" :isSquare="false" class="h-32 w-32"/>
        <SponsorLogo :sponsorId="rightSponsor" :isSquare="false"  class="h-32 w-32"/>
      </div>
    </template>
    <template v-slot:content>
      <div class="flex flex-col flex-grow -mx-16 -my-16">
        <p v-if="loadingGame" class="text-center text-xl mt-10 py-48 animate-pulse transition-opacity" :class="{'opacity-0': !loadingGame }">Game Loading...</p>
        <iframe v-if="miniGameUrl" class="mx-auto transition-opacity duration-500" :class="{'opacity-0': loadingGame }" :src="miniGameUrl" width="768" height="450" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
      </div>
      
    </template>
  </GameModal>
</template>

<script>
import GameModal from './GameModal.vue'
import SponsorLogo from '../GameSponsorLogo.vue'

export default {
  name: 'MiniGameModal',
  props: ['showModal', 'miniGameUrl', 'leftSponsor', 'rightSponsor'],
  inject: ['sponsors'],
  data() {
    return { hiding: false, loadingGame: true }
  },
  computed: {
    leftSponsorName() {
      const searchSponsorId = this.leftSponsor
      return this.sponsors.find(sponsor => sponsor.id == searchSponsorId).short_name
    },
    rightSponsorName() {
      const searchSponsorId = this.rightSponsor
      return this.sponsors.find(sponsor => sponsor.id == searchSponsorId).short_name
    }
  },
  watch: {
    showModal(newShow) {
      if (newShow) {
        setTimeout(() => { this.loadingGame = false }, 5500)
      } else {
        console.log(`Todo unload game from iframe`)
        this.loadingGame = true
      }
    }
  },
  methods: {

    onDismiss() {
      // console.log('dismiss mini game')
      this.$parent.showGameModal = false
    }
  },
  components: {
    GameModal,
    SponsorLogo,
  },
}
</script>
