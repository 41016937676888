<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="mt-24 mx-64">
      <!-- <div class="flex flex-col">
        <button v-on:click="checkMagicLinks" class="btn bg-game-blue hover:bg-game-blue-light">Query Magic Links</button>
      </div> -->
      <div class="flex flex-col">
        <h3 class="h3 py-2">({{ pendingUsers.length }}) Unused Magic Links</h3>
        <ul class="list-decimal">
          <li v-for="user in pendingUsers" :key="user.email">
            
            <p>
              "{{ user.email }}" - <a :href="user.link" class="hover:underline"><FontAwesomeIcon icon="hat-wizard" /> Magic Link</a> (Right Click - Copy link Address)
            </p>
          </li>
        </ul>
        <h3 class="h3 py-2">({{ successfulEmailCount }}) Successfully Delivered Emails</h3>  

        <ul class="list-decimal">
          <li v-for="user in successfulEmails" :key="user.email">            
            <p>
              {{ user.email }}
            </p>
          </li>
        </ul>
        <h3 class="h3 py-2">({{ failedEmailCount }}) Failed Emails</h3>
        <ul class="list-decimal">
        <li v-for="user in failedEmails" :key="user.email">            
            <p>
              {{ user.email }}
            </p>
          </li>
        </ul>
      </div>
      <!-- <div class="flex flex-col"> -->
                    
        <!-- <h3 class="h3 py-2">({{ successfulUserCount }}) Successful Players</h3> -->
        <!--  -->
      <!-- </div> -->

      <!-- <div class="flex flex-col">
        
        <ul>
          <li v-for="player in players" :key="player.id">
            {{ player.name }}
          </li>
        </ul>
      </div> -->

      <!-- <div class="flex flex-col">
        <h3 class="h3 py-2">({{ programmers.length }}) Programmers <button v-on:click="importProgrammers" class="btn bg-game-blue hover:bg-game-blue-light">Import Data</button></h3>
        <ul>
          <li v-for="programmer in programmers" :key="programmer.id">
            {{ programmer.name }}
          </li>
        </ul>
      </div> -->

      <!-- <div class="flex flex-col">
        <div class="flex flex-row justify-between">
          <h3 class="h3">Static Data</h3>
        </div>
        <ul>
          <li v-for="sponsor in sponsorsStatic" :key="sponsor.id">
            {{ sponsor.short_name }}
          </li>
        </ul>
      </div> -->
    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import { auth, db, playerCollection } from './../firebase.js'
import Footer from './../partials/Footer.vue'

export default {
  data() {
    return {
      pendingUsers: [],
      successfulUserCount: 0,
      successfulEmails: [],
      successfulEmailCount: 0,
      failedEmails: [],
      failedEmailCount: 0,
    }
  },
  name: 'AdminMagicLink',
  components: {
    Header,
    Footer,
  },
  computed: {
    isAuthorized() {
      return auth.currentUser != null
    },
  },
  methods: {
    checkMagicLinks() {

    },
  },
  mounted() {
    // const vm = this

    playerCollection.where('waitingForEmail', '==', true).get()
        .then(waitingForEmailSnapshot => {
            if (!waitingForEmailSnapshot.empty) {
                // this.pendingUsers = waitingForEmailSnapshot.docs
                this.pendingUsers = []
                waitingForEmailSnapshot.docs.forEach(queryDocumentSnapshot => {
                  db.collection('emails').where('to', '==', queryDocumentSnapshot.id).get()
                    .then(emailRecordSnapshot => {
                      if (!emailRecordSnapshot.empty) {
                        const snapData = emailRecordSnapshot.docs[0].data()
                        if (snapData) {
                          this.pendingUsers.push({ email: queryDocumentSnapshot.id, link: snapData.template.data.href})
                        }
                      }
                      
                    })
                    .catch(error => {
                      console.error(`Failed to execute emails query for ${queryDocumentSnapshot.id}. Details: ${error.message}`)
                    })
                })
                // this.pendingUsers = transformedUsers
            }
        })
        .catch(error => {
            console.error(`Failed to execute waitingForEmail query. Details: ${error.message}`)
        })

    playerCollection.where('waitingForEmail', '==', false).get()
        .then(waitingForEmailSnapshot => {
            if (!waitingForEmailSnapshot.empty) {
                this.successfulUserCount = waitingForEmailSnapshot.size

            }
        })
        .catch(error => {
            console.error(`Failed to execute waitingForEmail query. Details: ${error.message}`)
        })

    db.collection('emails').where('delivery.state', '==', 'SUCCESS').get()
        .then((emailSnapshot) => {
          console.log(`Found ${emailSnapshot.size} successful emails`)
          this.successfulEmailCount = emailSnapshot.size
          this.successfulEmails = []
          emailSnapshot.docs.forEach(emailDoc => {
            this.successfulEmails.push({ email: emailDoc.data().to })
          })
        })
        .catch((error) => {
          console.error(`Failed to query delivery success emails. Details: ${error.message}`)
        })
      
    db.collection('emails').where('delivery.state', '==', 'ERROR').get()
      .then((emailSnapshot) => {
        console.log(`Found ${emailSnapshot.size} error emails`)
        this.failedEmailCount = emailSnapshot.size
        this.failedEmails = []
        emailSnapshot.docs.forEach(emailDoc => {
          this.failedEmails.push({ email: emailDoc.data().to })
        })
      })
      .catch((error) => {
        console.error(`Failed to query delivery error emails. Details: ${error.message}`)
      })
  }
}
</script>
