<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
      <HeroHome />
      <WhatToExpect />
      <CharityInfo />
      <LogoGrid />

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import HeroHome from './../partials/HeroHome.vue'
import WhatToExpect from './../partials/WhatToExpect.vue'
import LogoGrid from './../partials/LogoGrid.vue'
import CharityInfo from './../partials/CharityInfo.vue'
import Footer from './../partials/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    HeroHome,
    WhatToExpect,
    CharityInfo,
    LogoGrid,
    Footer,
  },
}
</script>
