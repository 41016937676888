<template>
    <div class="absolute z-10" v-on:click="moveToSpace" :style="style">
		<transition-group name="fade">
			<img :key="'top-left'"  v-if="direction == 0 && revealed" class="transform" v-bind:class="{ 'rotate-180': this.flipped }" :src="require('@/images/game/board/TOPLEFT_LandedOn.png')" :style="imgStyle">
			<img :key="'top-left'"  v-if="direction == 0 && !revealed" class="transform" v-bind:class="{ 'rotate-180': this.flipped }" :src="require('@/images/game/board/TOPLEFT.png')" :style="imgStyle">
		</transition-group>
		<transition-group name="fade">
			<img :key="'top-right'"  v-if="direction == 1 && revealed" class="transform"  v-bind:class="{ 'rotate-180': this.flipped }" :src="require('@/images/game/board/TOPRIGHT_LandedOn.png')" :style="imgStyle">
			<img :key="'top-right'"  v-if="direction == 1 && !revealed" class="transform" v-bind:class="{ 'rotate-180': this.flipped }" :src="require('@/images/game/board/TOPRIGHT.png')" :style="imgStyle">
		</transition-group>
		<transition-group name="fade">
			<img :key="'bottom-left'"  v-if="direction == 2 && revealed" class="transform"  v-bind:class="{ 'rotate-180': this.flipped }" :src="require('@/images/game/board/BOTTOMLEFT_LandedOn.png')" :style="imgStyle">
			<img :key="'bottom-left'"  v-if="direction == 2 && !revealed" class="transform" v-bind:class="{ 'rotate-180': this.flipped }" :src="require('@/images/game/board/BOTTOMLEFT.png')" :style="imgStyle">
		</transition-group>
		<transition-group name="fade">
			<img :key="'bottom-right'" v-if="direction == 3 && revealed" class="transform"  v-bind:class="{ 'rotate-180': this.flipped }" :src="require('@/images/game/board/BOTTOMRIGHT_LandedOn.png')" :style="imgStyle">
			<img :key="'bottom-right'" v-if="direction == 3 && !revealed" class="transform" v-bind:class="{ 'rotate-180': this.flipped }" :src="require('@/images/game/board/BOTTOMRIGHT.png')" :style="imgStyle">
		</transition-group>
		<transition-group name="fade">
			<img :key="'vertical'" v-if="direction == 4 && revealed" class="transition transition-opacity"  v-bind:class="{ 'rotate-180': this.flipped }" :src="require('@/images/game/board/VERTICAL_LandedOn.png')" :style="imgStyle">
			<img :key="'vertical'" v-if="direction == 4 && !revealed" class="transition transition-opacity" v-bind:class="{ 'rotate-180': this.flipped }" :src="require('@/images/game/board/VERTICAL.png')" :style="imgStyle">
		</transition-group>
		<transition-group name="fade">
			<img :key="'horizontal'" v-if="direction == 5 && revealed" class="transition-opacity"  v-bind:class="{ 'rotate-180': this.flipped }" :src="require('@/images/game/board/HORIZONTAL_LandedOn.png')" :style="imgStyle">
			<img :key="'horizontal'" v-if="direction == 5 && !revealed" class="transition-opacity" v-bind:class="{ 'rotate-180': this.flipped }" :src="require('@/images/game/board/HORIZONTAL.png')" :style="imgStyle">
		</transition-group>

		<img v-if="revealed && game == true" class="absolute" :style="logoStyle" :src="require('@/images/game/board/GameSpace.png')">
		<img v-if="revealed && trivia == true" class="absolute" :style="logoStyle" :src="require('@/images/game/board/TriviaSpace.png')">
		<img v-if="revealed && glitch == true" class="absolute" :style="logoStyle" :src="require('@/images/game/board/GlitchSpace.png')">
		<img v-if="logoUrl" v-bind:class="{  'opacity-0': !revealed, 'opacity-100': revealed }" class="absolute transition transition-opacity duration-150" :style="logoStyle" :src="require('@/images/CircleLogo/' + logoUrl + '.png')">
    </div>
</template>

<script>

export default {
  name: 'BoardSpace',
  data() {
		return { revealed: false, newReveal: false }
	},
	inject: ['showSponsorInfo', 'showRandomGlitch', 'moveAvatarToSpace'],
	props: ['spaceIndex', 'x', 'y', 'direction', 'flipped', 'game', 'trivia', 'glitch', 'logoUrl', 'sponsorId', 'miniPrize', 'sponsorLeft', 'sponsorRight', 'dataKey', 'miniGameUrl', 'startRef'],
	computed: {
		style() {
			return {
				left: this.x - 100 + 'px',
				top: this.y - 150 + 'px',
				width: this.width + 'px',
				height: this.height + 'px',
			}
		},
		imgStyle() {
			return {
				// transform: this.transformForDirection(this.flipped, this.direction),
			}
		},
		logoStyle() {
			if (this.direction < 4) {
				return {
					width: 98 + 'px',
					height: 98 + 'px',
					top: (this.direction == 2 || this.direction == 3) ? '38px' : null,
					left: (this.direction == 1 || this.direction == 3) ? '36px' : null,
					right: (this.direction == 0 || this.direction == 2) ? '36px' : null,
					bottom: (this.direction == 0 || this.direction == 1) ? '36px' : null,
				}
			} else {
				if (this.flipped) {
					return {
						width: 98 + 'px',
						height: 98 + 'px',
						top: (this.direction == 4) ? '50px' : '32px',
						right: (this.direction == 4) ? '32px' : '55px', 
					}
				} else {
					return {
						width: 98 + 'px',
						height: 98 + 'px',
						top: (this.direction == 4) ? '52px' : '30px',
						left: (this.direction == 4) ? '42px' : '52px', 
					}
				}
			}
		},
		width() {
			if (this.direction == 4) {
				return 170
			}
			return 210
		},
		height() {
			if (this.direction == 5) {
				return 170
			}
			return 210
		},
	},
  components: {
  },
  methods: {
		transformForDirection(_flipped, _direction) {
			if (_flipped == true && _direction == 4) {
				return 'translateX(40px) scaleX(-100%)'
			} else if (_flipped == true && _direction == 5) {
				return 'translateY(40px) scaleY(-100%)'
			}
			return 'translate(0)'
		},
		clickedSpace() {
			if (this.moving) {
				console.log('Board space blocked attempted double move')
				return
			}
			this.$emit('clicked-board-space', this)
		},
		moveToSpace(actionDelay = 850) {
			if (this.moving) {
				console.log('Board space blocked attempted double move')
				return
			}
			//this.$emit('clicked-board-space', this)
			// this.$parent.$parent.spacesRevealed++

			// Center the space in our viewport
			this.$el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })

			this.moveAvatarToSpace(this.x - 100, this.y - 200, this.direction, this.flipped, this.$el, actionDelay)
		}
  }
}
</script>
