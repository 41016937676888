<template>
  <div v-on:click="showSponsor" class="flex justify-between items-center py-4 border-dashed border-b border-game-blue-light font-bold text-game-blue hover:text-game-blue-light cursor-pointer">
    <div class="">
      <p class="text-md">{{ short_name }}</p>
    </div>
    <!-- <div class=""><p class="text-xl">{{ nameForTier(tier) }} Sponsor</p></div> -->
  </div>
</template>

<script>

export default {
  name: 'SponsorRow',
  props: ['sponsorId', 'short_name', 'name', 'tier'],
  inject: ['showSponsorInfo'],
  components: {
  },
  methods: {
    nameForTier(tier) {
      if (tier == 1) {
        return "I"
      } else if (tier == 2) {
        return "II"
      } else if (tier == 3) {
        return "III"
      }
      return "Unknown?"
    },
    showSponsor() {
      // console.log(`Show sponsor ${this.sponsorId}`)
      this.showSponsorInfo(this.sponsorId)
    }
  },
}
</script>
