/* Import the firebase SDK and extend with firestore */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

var firebaseConfig = {
  apiKey: "AIzaSyBiOncgGoQy4vnHlAONgrXpg99fG79_bNI",
  authDomain: "attfair-ff8bc.firebaseapp.com",
  databaseURL: "https://attfair-ff8bc.firebaseio.com",
  projectId: "attfair-ff8bc",
  storageBucket: "attfair-ff8bc.appspot.com",
  messagingSenderId: "22520214963",
  appId: "1:22520214963:web:451fc4a291070d6659c7c6",
  measurementId: "G-4B6HM7EKFW"
}
const app = firebase.initializeApp(firebaseConfig)

const auth = app.auth()
const db = app.firestore()
const arrayUnion = firebase.firestore.FieldValue.arrayUnion
const arrayRemove = firebase.firestore.FieldValue.arrayRemove
const increment = firebase.firestore.FieldValue.increment

const programmerCollection = db.collection('programmers')
const playerCollection = db.collection('players')
const prizeCollection = db.collectionGroup('prizes')
const emailCollection = db.collection('email')

const sponsorForEmail = (email, sponsorCallback) => {
  programmerCollection.where('emails', 'array-contains', this.currentUser.email).get()
    .then((programmerSnap) => {
        if (!programmerSnap.empty) {
            const sponsorId = programmerSnap.docs[0].id
            console.dir(programmerSnap)
            sponsorCallback(sponsorId)
        }
    })
    .catch((error) => {
        console.error(`Failed to find programmer for email ${this.currentUser.email}. Details: ${error.message}`)
    })    
}

export {
    auth,
    db,
    arrayUnion,
    arrayRemove,
    increment,
    programmerCollection,
    playerCollection,
    prizeCollection,
    sponsorForEmail,
}