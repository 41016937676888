<template>
<section>
    
    <div class="max-w-5xl mx-auto sm:px-6">
        <h2 class="h2 pb-4 border-b border-gray-100">Achievements</h2>

        <div class="flex justify-between items-center py-6 border-t border-b border-gray-100">
            <div class="flex-grow">
                <p>Category</p>
            </div>
            <div class="flex-none w-32"><p class="text-center">Completed</p></div>
            <div class="flex-none w-32"><p class="text-center">Total</p></div>
        </div>
        <div class="flex justify-between items-center py-6 border-b border-gray-100">
            <div class="flex-grow">
                <p class="text-sm underline">Glitch in the System</p>
                <p class="text-sm">Land on 3 Randomizer Cells</p>
            </div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide">{{ progressGlitch }}</p></div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide text-blue-600 font-bold">3</p></div>
        </div>

        <div class="flex justify-between items-center py-6 border-b border-gray-100">
            <div class="flex-grow">
                <p class="text-sm underline">Game Master</p>
                <p class="text-sm">Play all 7 Mini Games</p>
            </div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide">{{ progressGM }}</p></div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide text-blue-600 font-bold">7</p></div>
        </div>

        <div class="flex justify-between items-center py-6 border-b border-gray-100">
            <div class="flex-grow">
                <p class="text-sm underline">L33T H@CK3R</p>
                <p class="text-sm">Collect All 6 Data Keys</p>
            </div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide">{{ progressHacker }}</p></div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide text-blue-600 font-bold">6</p></div>
        </div>

        <div class="flex justify-between items-center py-6 border-b border-gray-100">
            <div class="flex-grow">
                <p class="text-sm underline">Playing with Power</p>
                <p class="text-sm">Light Up All 63 Power Cells</p>
            </div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide">{{ progressPower }}</p></div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide text-blue-600 font-bold">63</p></div>
        </div>

        <div class="flex justify-between items-center py-6 border-b border-gray-100">
            <div class="flex-grow">
                <p class="text-sm underline">System Overload</p>
                <p class="text-sm">Watch All Content</p>
            </div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide">{{ progressOverload }}</p></div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide text-blue-600 font-bold">71</p></div>
        </div>

        <div class="flex justify-between items-center py-6 border-b border-gray-100">
            <div class="flex-grow">
                <p class="text-sm underline">Mainframe Complete</p>
                <p class="text-sm">Watch all Mainstage Content</p>
            </div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide">{{ progressMainStage }}</p></div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide text-blue-600 font-bold">8</p></div>
        </div>

        <div class="flex justify-between items-center py-6 border-b border-gray-100">
            <div class="flex-grow">
                <p class="text-sm underline">Robotic Upgrade</p>
                <p class="text-sm">Fully Upgrade a Robot</p>
            </div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide">1</p></div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide text-blue-600 font-bold">1</p></div>
        </div>

        <div class="flex justify-between items-center py-6 border-b border-gray-100">
            <div class="flex-grow">
                <p class="text-sm underline">Binary Brilliance</p>
                <p class="text-sm">Decipher binary code message hidden within</p>
            </div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide">0</p></div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide text-blue-600 font-bold">1</p></div>
        </div>

        <div class="flex justify-between items-center py-6 border-b border-gray-100">
            <div class="flex-grow">
                <p class="text-sm underline">Code Cracker</p>
                <p class="text-sm">Decipher the morse code audio message</p>
            </div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide">0</p></div>
            <div class="flex-none w-32"><p class="text-base text-center tracking-wide text-blue-600 font-bold">1</p></div>
        </div>

    </div>
</section>          
</template>

<script>
export default {
  name: 'Achievements',
  inject: ['currentUser'],
  props: ['progressGlitch', 'progressGM', 'progressHacker', 'progressPower', 'progressOverload', 'progressMainStage', 'progressBinary'],
  components: {
  }
}
</script>