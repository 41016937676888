<template>
  <GameModal :showModal="showModal" :dismissFunc="onDismiss" :modalBackgroundUrl="require('@/images/game/ui/bg-glitch.svg')">
    <template v-slot:logo>
      <img class="mx-auto w-32" :src="require('@/images/game/board/TriviaSpace.png')"/>
    </template>
    <template v-slot:content>
      <div class="flex flex-col justify-center h-full text-center">
        <h2 class="h2 uppercase">[AT&amp;T Trivia]</h2>
        <p v-if="triviaTitle" class="text-xl py-2 leading-relaxed">{{ triviaTitle }}</p>
        <p v-if="triviaDescription" class="py-16 text-xl text-left leading-relaxed">{{ triviaDescription }}</p>
      </div>
    </template>
  </GameModal>
</template>

<script>
import GameModal from './GameModal.vue'

export default {
  name: 'TriviaModal',
  props: ['showModal', 'description', 'triviaIndex'],
  inject: ['hideSponsorInfo'],
  data() {
    return { 
      hiding: false,
      triviaDescriptions: ['DIRECTV was the first MVPD (multichannel video programming distributor) to carry all 4 golf majors – the Masters, U.S. Open Championship, The Open Championship and the PGA Championship – in 4K HDR.', 'NFL SUNDAY TICKET is the only ticket to your virtual seat on the 50-yard line—now included at no extra cost with CHOICE Package and above for new customers.', 'AT&T TV offers 500 hours of cloud DVR with unlimited simultaneous recordings that you can access anytime, anywhere, unlike with national cable providers.', ' In 2000, DIRECTV introduced the first live in-flight television service for airlines.'],
      triviaTitles: ['Four!', 'It’s game time!', 'No more recording conflicts!', 'First to the skies!'],
    }
  },
  computed: {
    triviaTitle() {
      return this.triviaTitles[this.triviaIndex]
    },
    triviaDescription() {
      return this.triviaDescriptions[this.triviaIndex]
    }
  },
  methods: {
    onDismiss() {
      // console.log('dismiss info')
      this.$parent.$data.showTriviaModal = false
    }
  },
  components: {
    GameModal,
  },
}
</script>
