<template>
    <div class="justify-self-end">
        <a href="https://programmerfair2020.vbooth.me/booth/Vdk3OPdX" target="_blank"><img :src="require('@/images/game/buttons/button-camera.svg')" /></a>
        <!-- <iframe v-if="active" src="https://programmerfair2020.vbooth.me/booth/Vdk3OPdX" width="600" height="765" /> -->
    </div>
</template>

<script>

export default {
  name: 'Photobooth',
  data() {
    return { active: false }
  },
  components: {
  },
}
</script>
