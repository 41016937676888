<template>
    <section class="max-w-xl z-30">
        <img v-on:click="listVisible = true" v-if="!listVisible" class="cursor-pointer" :src="require('@/images/game/buttons/button-achievements.svg')" />
        <div v-on:click="hideIfNotFocused" v-if="listVisible" class="mt-4 ml-4 mr-2 border" :style="{ 'border-image': 'url(' + background + ') 64 fill' }" style="border-image-slice: 64 fill; border-image-width: 64px 64px; border-width: 64px;">
            <div class="-mx-4 -my-2">
              <p class="my-2 text-2xl px-20 font-bold uppercase text-center text-game-blue tracking-wider">[Achievements]</p>
              <AchievementRow name="Glitch in the System" description="Land on 3 Randomizer Cells" :progress="progressGlitch" :requiredProgress="3"/>
              <AchievementRow name="Game Master" description="Play All 7 Mini Games" :progress="progressMiniGames" :requiredProgress="7"/>
              <AchievementRow name="L33T H@CK3R" description="Collect All 6 Data Keys" :progress="progressHacker" :requiredProgress="6"/>
              <AchievementRow name="Playing with Power" description="Light Up All 63 Power Cells" :progress="progressPower" :requiredProgress="63"/>
              <AchievementRow name="System Overload" description="Watch All Content" :progress="progressOverload" :requiredProgress="71"/>
              <AchievementRow name="Mainframe Complete" description="Watch All Mainstage Content" :progress="progressMainStage" :requiredProgress="8"/>
              <AchievementRow name="Robotic Upgrade" description="Fully Upgrade your Robot" :progress="6" :requiredProgress="6"/>

              <div class="h-1 my-2 border-b border-game-blue-light border-dashed"></div>
              <AchievementRow v-on:typed-valid-message="validateBinary" ref="binary" name="Binary Brilliance" validMessage="Mobilizing Your World" />
              <AchievementRow v-on:typed-valid-message="validateCracker" ref="cracker" name="Code Cracker" validMessage="1876" />
            </div>
        </div>
    </section>
</template>

<script>
import AchievementRow from './GameAchievementRow.vue'

export default {
  name: 'AchievementList',
  props: ['progressMiniGames', 'progressPower', 'progressGlitch', 'progressMainStage', 'progressHacker', 'achievementBinary', 'achievementCracker'],
  data: () => {
    return { 
      listVisible: false,
      editingText: false,
      background: require('@/images/game/ui/bg-panel.svg'),
    }
  },
  computed: {
    progressOverload() {
      return Math.min(this.progressMainStage, 8) + Math.min(this.progressPower, 63)
    },
  },
  methods: {
    hideIfNotFocused() {
      if (!this.editingText) {
        // console.log('Hiding because not editing')
        this.listVisible = !this.listVisible
      }
      // console.log('Tried to hide but was editing')
      return false
    },
    validateBinary() {
      this.$parent.$data.achievementBinary = true
    },
    validateCracker() {
      this.$parent.$data.achievementCracker = true
    }
  },
  components: {
      AchievementRow,
  },
}
</script>
