<template>
    <div v-on:click="muted = !muted" class="" style="height: 170px; width: 170px;">
        <img v-if="!muted" class="absolute bottom-0 left-0"  :src="require('@/images/game/buttons/button-music-on.svg')" />
        <img v-if="muted" class="absolute bottom-0 left-0"  :src="require('@/images/game/buttons/button-music-off.svg')" />
    </div>
</template>

<script>

export default {
  name: 'MuteButton',
  data() {
    return { muted: false }
  },
  mounted() {
    if (!this.$route.query.skipBackgroundMusic) {
      setTimeout(this.startBackgroundMusic, 15000)
    }
  },
  computed: {
    volumeLevel() {
      return this.muted ? 0 : 0.3
    }
  },
  watch: {
    muted() {
      if (this.bgAudio) {
        this.bgAudio.volume = this.volumeLevel
      }
    }
  },
  methods: {
    startBackgroundMusic() {
      if (!this.bgAudio) {
        this.bgAudio = new Audio(require('@/game/music/bg.mp3'));
        this.bgAudio.loop = true;
        this.bgAudio.volume = this.volumeLevel
      }      
      this.bgAudio.play();
    },
    muteBackgroundMusic() {
      this.muted = true
    }
  },
  components: {
  },
}
</script>
