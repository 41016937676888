<template>
  <section class="relative">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">

      <!-- Hero content -->
      <div class="pt-32 pb-12 md:pt-40 md:pb-20">

        <!-- Section header -->
        <div class="text-center pb-12 md:pb-16">
          <h1 class="text-5xl md:text-6xl tracking-tighter font-bold mb-4" data-aos="zoom-y-out">Programmer Fair <span class="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">2020</span></h1>
          <div class="max-w-4xl mx-auto">
            <p class="text-xl text-gray-600 mb-4" data-aos="zoom-y-out" data-aos-delay="250">Welcome to our virtual board game!  We are LIVE from 10 a.m. to 4 p.m. PT on Dec. 17th. </p>
            <p class="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="350">Check out some details below, and click on the appropriate Sign In link when ready.</p>
            <div class="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center">
              <div>
                <a v-if="!currentUser" class="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0" href="/signin">Employee Sign In</a>
              </div>
              <div>
                 <a v-if="!currentUser" class="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4" href="/programmer-signin">Programmer Sign In</a>
              </div>
              <a v-if="currentUser" href="/game/" class="btn btn-sm text-white bg-blue-600 hover:bg-blue-700 ml-3">
                Enter Game
                <svg class="w-3 h-3 fill-current text-white flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill-rule="nonzero" />
                </svg>
              </a>
            </div>
          </div>
        </div>

        <!-- Hero image -->
        <div>
          <div class="relative flex justify-center mb-4" data-aos="zoom-y-out" data-aos-delay="450">
            <div class="flex flex-col justify-center">
              <img class="mx-auto" :src="require('@/images/Screensaver.png')" />
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
// import Modal from './../utils/Modal.vue'
import { auth } from '../firebase.js';

export default {
  name: 'HeroHome',
  components: {
    // Modal,
  },
  data: function () {
    return {
      videoModalOpen: false,
      currentUser: auth.currentUser,
    }
  },
}
</script>