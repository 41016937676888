<template>
    <div class="flex py-2">
      <div v-if="isCompleted || textIsValid" class="flex-col items-stretch">
        <img class="mt-4 pr-2" :src="require('@/images/game/buttons/icon-check.svg')">
      </div>
      <div class="flex-col flex-grow justify-between items-stretch">
          <div class="">
              <p class="text-game-blue text-md">{{ modifiedAchievementName }}</p>
          </div>
          <p class="py-1 text-game-blue text-xs">{{ (isCompleted || textIsValid) ? 'COMPLETE' : description }}</p>
          <div v-if="!isCompleted && !validMessage" class="pt-1">
              <div class="relative">
                  <div class="overflow-hidden h-1 flex rounded bg-game-blue-dark">
                      <div :style="style" class="shadow-none flex flex-col rounded text-center whitespace-nowrap text-white justify-center bg-game-blue-light"></div>
                  </div>
              </div>
          </div>
          <div v-if="validMessage && !textIsValid" class="">
            <input v-on:focus="textReceivedFocus" v-on:blur="textLostFocus" class="mt-1 form-input w-full text-xs border-game-blue placeholder-game-blue bg-game-dark text-game-blue" v-model="currentText" placeholder="Enter message here">
          </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'AchievementRow',
  props: ['name', 'description', 'requiredProgress', 'progress', 'validMessage'],
  data() {
    return { currentText: '' }
  },
  computed: {
    style() {
      return { width: (this.progress / this.requiredProgress) * 100 + '%'}
    },
    isCompleted() {
      return this.progress >= this.requiredProgress
    },
    textIsValid() {
      if (!this.validMessage) {
        return false
      }
      return this.currentText.toLowerCase() == this.validMessage.toLowerCase() 
    },
    modifiedAchievementName() {
      if (this.validMessage) {
        if (this.textIsValid) {
          return this.name
        }
        return '[+] ' + this.name
      }
      return this.name
    }
  },
  components: {
  },
  methods: {
    // The following two methods will prevent the default dismiss behavior when
    // a user is attempting to enter text for the achievement text field
    textReceivedFocus() {
      // console.log(`Text received focus`)
      this.$parent.editingText = true
    },
    textLostFocus() {
      // console.log(`Text lost focus`)
      this.$parent.editingText = false
    },
  },
  watch: {
    currentText(newCurrentText) {
      if (this.textIsValid) {
        console.log(`Correct!`)
        this.$emit('typed-valid-message', newCurrentText)
      }
    }
  }
}
</script>
