<template>
<section class="">

        <div class="transform rotate-90 -translate-y-10 flex items-center justify-center">
          <svg width="3px" height="81px" viewbox="0 0 3 81" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0L2 0L2 80L0 80L0 0Z" transform="translate(0.5 0.5)" id="Rectangle" fill="#009FDB" fill-rule="evenodd" stroke="none" />
          </svg>
        </div>

        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <div class="pb-12 md:pb-18">

            <!-- Section header -->
            <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
              <h2 class="h2 mb-4" data-aos="zoom-y-out">What to expect at this year's event</h2>
            </div>

            <!-- Items -->
            <div class="text-center max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none" data-aos-id-blocks>

              <!-- 1st item -->
              <div class="relative flex flex-col p-6 bg-white rounded shadow-xl py-6" data-aos="zoom-y-out" data-aos-anchor="[data-aos-id-blocks]">
                <img :src="require('@/images/Interactiveboardgame.svg')" />
                <h4 class="text-xl font-bold leading-snug tracking-tight py-2">Interactive Board Game</h4>
                <p class="text-sm text-gray-600">Navigate the board to uncover programmer information, play games, &amp; earn achievements!</p>
              </div>

              <!-- 2nd item -->
              <div class="relative flex flex-col p-6 bg-white rounded shadow-xl py-6 pb-10" data-aos="zoom-y-out" data-aos-delay="150" data-aos-anchor="[data-aos-id-blocks]">
                <img :src="require('@/images/Mini-game.svg')" />
                <h4 class="text-xl font-bold leading-snug tracking-tight py-2">Mini Games</h4>
                <p class="text-sm text-gray-600">Play fun retro mini-games, brought to you by our sponsors.</p>
              </div>

              <!-- 3rd item -->
              <div class="relative flex flex-col p-6 bg-white rounded shadow-xl py-6 pb-10" data-aos="zoom-y-out" data-aos-delay="300" data-aos-anchor="[data-aos-id-blocks]">
                <img :src="require('@/images/Sneekpeek.svg')" />
                <h4 class="text-xl font-bold leading-snug tracking-tight py-2">Sneak Peeks</h4>
                <p class="text-sm text-gray-600">Find out what our programmers have been up to, and what may be to come in 2021!</p>
              </div>

              <!-- 4th item -->              
              <div class="relative flex flex-col p-6 bg-white rounded shadow-xl py-6 pb-10" data-aos="zoom-y-out" data-aos-delay="750" data-aos-anchor="[data-aos-id-blocks]">
                <img :src="require('@/images/Mainstage.svg')" />
                <h4 class="text-xl font-bold leading-snug tracking-tight py-2">Virtual Main Stage</h4>
                <p class="text-sm text-gray-600">Watch special content from our top sponsors on the main screen. </p>
              </div>


              <!-- 5th item -->

              <div class="relative flex flex-col p-6 bg-white rounded shadow-xl py-6" data-aos="zoom-y-out" data-aos-delay="450" data-aos-anchor="[data-aos-id-blocks]">
                <img :src="require('@/images/Charity.svg')" />
                <h4 class="text-xl font-bold leading-snug tracking-tight py-2">Support Charities</h4>
                <p class="text-sm text-gray-600">Find and spin the multiple prize randomizers to earn donations toward charity!</p>
              </div>

              <!-- 6th item -->
              <div class="relative flex flex-col p-6 bg-white rounded shadow-xl py-6" data-aos="zoom-y-out" data-aos-delay="600" data-aos-anchor="[data-aos-id-blocks]">
                <img :src="require('@/images/Prizes.svg')" />
                <h4 class="text-xl font-bold leading-snug tracking-tight py-2">Win Prizes</h4>
                <p class="text-sm text-gray-600">Spin multiple prize randomizers for a chance to win a prize from one of our programmers.</p>
              </div>

            </div>

          </div>
        </div>

        <div class="transform rotate-90 translate-y-10 flex items-center justify-center">
          <svg width="3px" height="81px" viewbox="0 0 3 81" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0L2 0L2 80L0 80L0 0Z" transform="translate(0.5 0.5)" id="Rectangle" fill="#009FDB" fill-rule="evenodd" stroke="none" />
          </svg>
        </div>
      </section>
</template>

<script>
export default {
  name: 'WhatToExpect',
  components: {
    
  },  
}
</script>