<template>
      <div class="transform scale-50" style="--transform-translate-x: -40%; --transform-translate-y: -40%; --transform-scale-x: .45; --transform-scale-y: .45;">
        <img class="absolute top-0 left-0 max-w-none" :src="require('@/images/game/board/GameBoardClean.png')" style="filter: brightness(50%)">
        <!-- v-on:click="clickedBackground" -->

        <GameVideoPlayer :mainStageVideoId="mainStageVideoId" />

        <BoardSpace class="z-0" style="filter: brightness(70%);" :key="'underpass'" :flipped="false" :skip="true" :x="2468" :y="2440" :direction="4" />

        <div ref="boardSpaces" id="board-spaces">        
          <BoardSpace 
            v-for="(space, spaceIndex) in spaces" 
              v-bind:key="spaceIndex" 
              v-bind:spaceIndex="spaceIndex"
              v-bind:flipped="space.flipped" 
              v-bind:direction="space.direction" 
              v-bind:x="space.x" 
              v-bind:y="space.y" 
              v-bind:dataKey="space.dataKey"
              v-bind:game="space.game" 
              v-bind:trivia="space.trivia" 
              v-bind:glitch="space.glitch" 
              v-bind:miniPrize="space.miniPrize"
              v-bind:logoUrl="space.logoUrl" 
              v-bind:sponsorId="space.sponsorId"
              v-bind:sponsorLeft="space.leftSponsor"
              v-bind:sponsorRight="space.rightSponsor"
              v-bind:miniGameUrl="space.miniGameUrl"
              v-bind:ref="space.reference || space.x + ' ' + space.y"
              v-bind:startRef="space.reference || ''"
              v-on:clicked-board-space="clickedBoardSpace">
          </BoardSpace>
        </div>

        <Avatar ref="avatar" :avatarIndex="avatarIndex" />
    </div>
</template>

<script>
import BoardSpace from './GameBoardSpace.vue'
import GameVideoPlayer from './GameVideoPlayer.vue'
import Avatar from './GameAvatar.vue'

export default {
  name: 'Board',
  props: ['avatarIndex'],
  inject: ['sponsors'],
  data() {
    return { 
      parentGame: null,
      mainStageVideoId: '483255340',
      spaces: [
        { x: 1270, y: 1433, direction: 0, flipped: false, logoUrl: 'Multimedios', sponsorId: 'MLT' }, 
        { x: 1479, y: 1400, direction: 3, flipped: false, logoUrl: 'CineLatino', sponsorId: 'CL' }, 
        { x: 1521, y: 1200, direction: 4, flipped: true, logoUrl: 'Pasiones', sponsorId: 'PAS' }, 
        { x: 1521, y: 1000, direction: 4, flipped: true, logoUrl: 'MegaTV', sponsorId: 'MEGATV' }, 
        { x: 1480, y: 790, direction: 1, flipped: false, logoUrl: 'Caracol', sponsorId: 'CARA' }, 
        { x: 1270, y: 762, direction: 2, flipped: false, game: true, leftSponsor: 'MLT', rightSponsor: 'MEGATV', miniGameUrl: 'https://d3li4kpb4w845e.cloudfront.net/en/x-trench-run-samgreen/index.html' }, 
        { x: 1270, y: 553, direction: 0, flipped: false, trivia: true }, 
        { x: 1480, y: 553, direction: 5, flipped: true, logoUrl: 'ViacomCBS', sponsorId: 'VIA', reference: 'startEntertainment', dataKey: 'International' }, 
        { x: 1680, y: 553, direction: 5, flipped: true, miniPrize: true, logoUrl: 'ViacomCBS', sponsorId: 'VIA'}, 
        { x: 1880, y: 553, direction: 5, flipped: true, logoUrl: 'RevoltTV', sponsorId: 'RTV' }, 
        { x: 2080, y: 553, direction: 5, flipped: true, logoUrl: 'IonMediaNetworks', sponsorId: 'ION'}, 
        { x: 2279, y: 554, direction: 1, flipped: false, logoUrl: 'Up Entertainment', sponsorId: 'UP'}, 
        { x: 2320, y: 764, direction: 4, flipped: true, logoUrl: 'NBCuniversal', sponsorId: 'NBC'}, 
        { x: 2320, y: 964, direction: 2, flipped: false, game: true, leftSponsor: 'ION', rightSponsor: 'UNI', miniGameUrl: 'https://d3li4kpb4w845e.cloudfront.net/en/parkour-run-samgreen/index.html' }, 
        { x: 2520, y: 964, direction: 3, flipped: false, logoUrl: 'AE', sponsorId: 'AE'}, 
        { x: 2548, y: 754, direction: 0, flipped: false, logoUrl: 'Cleo', sponsorId: 'CLEO'}, 
        { x: 2758, y: 768, direction: 5, flipped: false, logoUrl: 'GameshowNetwork', sponsorId: 'GSN'}, 
        { x: 2958, y: 768, direction: 5, flipped: false, logoUrl: 'QVC', sponsorId: 'QVC'}, 
        { x: 3158, y: 768, direction: 5, flipped: false, logoUrl: 'Fox', sponsorId: 'FOX'}, 
        { x: 3358, y: 768, direction: 5, flipped: false, logoUrl: 'AXStv', sponsorId: 'AXS'}, 
        { x: 3552, y: 726, direction: 3, flipped: false, game: true, leftSponsor: 'CLEO', rightSponsor: 'QVC', miniGameUrl: 'https://d3li4kpb4w845e.cloudfront.net/en/triangle-wars-samgreen/index.html' }, 
        { x: 3580, y: 518, direction: 0, flipped: false, logoUrl: 'CrownMedia', sponsorId: 'CMF', reference: 'startFamily', dataKey: 'Entertainment' }, 
        { x: 3790, y: 518, direction: 5, flipped: true, miniPrize: true, logoUrl: 'CrownMedia', sponsorId: 'CMF'}, 
        { x: 3990, y: 518, direction: 5, flipped: true, logoUrl: 'Insp', sponsorId: 'INSP'}, 
        { x: 4190, y: 518, direction: 1, flipped: false, logoUrl: 'CartoonNetwork', sponsorId: 'CN'}, 
        { x: 4231, y: 728, direction: 4, flipped: true, logoUrl: 'FirstMedia', sponsorId: 'FIRST'}, 
        { x: 4231, y: 928, direction: 4, flipped: true, logoUrl: 'Discovery', sponsorId: 'DI'}, 
        { x: 4189, y: 1128, direction: 3, flipped: false, miniPrize: true, logoUrl: 'Discovery', sponsorId: 'DI'},
        { x: 3980, y: 1150, direction: 0, flipped: false, game: true, leftSponsor: 'INSP', rightSponsor: 'CN', miniGameUrl: 'https://d3li4kpb4w845e.cloudfront.net/en/space-alien-invaders-samgreen/index.html' }, 
        { x: 3980, y: 1360, direction: 2, flipped: false, logoUrl: 'ESPN', sponsorId: 'ESPN', reference: 'startSports', dataKey: 'Family'}, 
        { x: 4189, y: 1394, direction: 1, flipped: false, miniPrize: true, logoUrl: 'ESPN', sponsorId: 'ESPN'}, 
        { x: 4226, y: 1604, direction: 4, flipped: true, logoUrl: 'MLB', sponsorId: 'MLB'}, 
        { x: 4188, y: 1804, direction: 3, flipped: false, glitch: true }, 
        { x: 3985, y: 1840, direction: 0, flipped: false, logoUrl: 'OutdoorChannel', sponsorId: 'OUT'}, 
        { x: 3985, y: 2050, direction: 4, flipped: true, logoUrl: 'MavTV', sponsorId: 'MATV'}, 
        { x: 3985, y: 2250, direction: 4, flipped: true, game: true, leftSponsor: 'FOX', rightSponsor: 'OUT', miniGameUrl: 'https://d3li4kpb4w845e.cloudfront.net/en/tower-of-colors-samgreen/index.html' }, 
        { x: 3956, y: 2450, direction: 3, flipped: false, trivia: true }, 
        { x: 3758, y: 2492, direction: 5, flipped: false, logoUrl: 'Showtime', sponsorId: 'SHOWTIME', reference: 'startNews', dataKey: 'Sports'}, 
        { x: 3548, y: 2450, direction: 2, flipped: false, miniPrize: true, logoUrl: 'Showtime', sponsorId: 'SHOWTIME'}, 
        { x: 3548, y: 2250, direction: 4, flipped: false, logoUrl: 'NewsMax', sponsorId: 'NMAX'}, 
        { x: 3516, y: 2040, direction: 1, flipped: false, glitch: true }, 
        { x: 3318, y: 2040, direction: 5, flipped: true, logoUrl: 'EWTN', sponsorId: 'EWTN'}, 
        { x: 3110, y: 2040, direction: 0, flipped: false, logoUrl: 'EntertainmentStudios', sponsorId: 'ESW'}, 
        { x: 3110, y: 2250, direction: 4, flipped: true, game: true,  leftSponsor: 'TNT', rightSponsor: 'FIRST', miniGameUrl: 'https://d3li4kpb4w845e.cloudfront.net/en/shoot-the-asteroids-samgreen/index.html' }, 
        { x: 3110, y: 2445, direction: 4, flipped: true, trivia: true }, 
        { x: 3076, y: 2645, direction: 3, flipped: false, logoUrl: 'AMC', sponsorId: 'AMC'}, 
        { x: 2878, y: 2686, direction: 5, flipped: false, miniPrize: true, logoUrl: 'AMC', sponsorId: 'AMC'}, 
        { x: 2685, y: 2686, direction: 5, flipped: false, logoUrl: 'Ovation', sponsorId: 'OVA'}, 
        { x: 2475, y: 2645, direction: 2, flipped: false, logoUrl: 'ShortsTV', sponsorId: 'SHORTZ'}, 
        { x: 2468, y: 2230, direction: 0, flipped: false, logoUrl: 'Starz', sponsorId: 'STARZ', reference: 'startMovies', dataKey: 'News'}, 
        { x: 2678, y: 2230, direction: 1, flipped: false, miniPrize: true, logoUrl: 'Starz', sponsorId: 'STARZ' }, 
        { x: 2678, y: 2440, direction: 3, flipped: false, glitch: true}, 
        { x: 2480, y: 2480, direction: 5, flipped: false, logoUrl: 'MGM', sponsorId: 'MGM'}, 
        { x: 2280, y: 2480, direction: 5, flipped: false, logoUrl: 'Epix', sponsorId: 'EPIX'}, 
        { x: 2080, y: 2480, direction: 5, flipped: false, logoUrl: 'TNT', sponsorId: 'TNT'}, 
        { x: 1880, y: 2480, direction: 5, flipped: false, game: true, leftSponsor: 'EPIX', rightSponsor: 'SHORTZ', miniGameUrl: 'https://d3li4kpb4w845e.cloudfront.net/en/cubeform-samgreen/index.html' }, 
        { x: 1680, y: 2480, direction: 5, flipped: false, trivia: true }, 
        { x: 1480, y: 2480, direction: 5, flipped: false, logoUrl: 'HBOMAX', sponsorId: 'HBO', reference: 'startInternational', dataKey: 'Movie' }, 
        { x: 1270, y: 2440, direction: 2, flipped: false, miniPrize: true, logoUrl: 'HBOMAX', sponsorId: 'HBO'}, 
        { x: 1270, y: 2243, direction: 4, flipped: false, logoUrl: 'TFC', sponsorId: 'TFC'}, 
        { x: 1270, y: 2043, direction: 4, flipped: false, logoUrl: 'Univision', sponsorId: 'UNI'}, 
        { x: 1270, y: 1843, direction: 4, flipped: false, logoUrl: 'Vme', sponsorId: 'VME'}, 
        { x: 1270, y: 1643, direction: 4, flipped: false, logoUrl: 'Olympusat', sponsorId: 'OLYM'}, 
      ]
    }
  },
  components: {
    BoardSpace,
    GameVideoPlayer,
    Avatar,
  },
  methods: {
    clickedBoardSpace(space) {
      // const avatar = this.$refs.avatar
      // const boardSpaces = this.$refs.boardSpaces
      console.log(`Player clicked board space ${space}`)
      this.$parent.$data.spacesRevealed += 1
      // this.$parent.moveTo()
    },
    clickedBackground() {
      this.parentGame.moveToNextSpace()
    }
  },
  mounted() {
    this.parentGame = this.$parent
  }
  // mounted() {
  //   this.$refs.mainStageWrapper.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
  // }
}
</script>
