<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <section class="bg-gradient-to-b from-gray-100 to-white">
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <div class="pt-32 pb-12 md:pt-40 md:pb-20">

            <!-- Page header -->
            <div class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
              <h1 class="h1">Welcome, Programmers!</h1>
            </div>

            <!-- Form -->
            <div class="max-w-sm mx-auto">
              <form v-on:submit.prevent="sendMagicLink">
                <div class="flex flex-wrap -mx-3 mb-4">
                  <div class="w-full px-3">
                    <div class="flex justify-between">
                      <label class="block text-gray-800 text-sm font-medium mb-1" for="email">Work Email</label>
                      <router-link to="/support" class="text-sm font-medium text-blue-600 hover:underline">Having troubles signing in?</router-link>
                    </div>                    
                    <input id="email" type="email" v-model.trim="emailAddress" class="form-input w-full text-gray-800" placeholder="Enter your email address" required />
                  </div>
                </div>
                
                <div class="flex flex-wrap -mx-3 mt-6">
                  <div class="w-full px-3">
                    <button class="btn text-white bg-blue-600 hover:bg-blue-700 w-full">Sign In</button>
                  </div>
                </div>                                 
              </form>

              <div class="flex flex-wrap -mx-3 mt-6">
                <div class="w-full px-3">
                  <p class="text-center">OR</p>
                </div>
              </div>

              <div class="flex flex-wrap -mx-3 mt-6">
                <div class="w-full px-3">
                  <div>
                    <a class="btn text-white bg-gray-900 hover:bg-gray-800 w-full" href="/signin">Employee Sign In</a>
                  </div>
                </div>
              </div>

              <!-- <div class="flex flex-wrap -mx-3 mt-6">
                <div class="w-full px-3 text-center">
                  <p>By creating an account, you agree to the <a href="https://www.att.com/legal/terms.attWebsiteTermsOfUse.html">terms &amp; conditions</a>, and our <a href="#">privacy policy</a>.</p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </section>

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import { playerCollection} from './../firebase.js'

export default {
  name: 'VendorSignIn',
  data() {
    return { emailAddress: '', }
  },
  inject: ['currentUser'],
  methods: {
    sendMagicLink() {
      const router = this.$router;
      // const actionCodeSettings = {
      //   url: window.location.origin + `/finish-sign-in?firstName=${this.firstName}&lastName=${this.lastName}`,
      //   handleCodeInApp: true,
      // };
      
      playerCollection.doc(this.emailAddress).set({ isProgrammer: true, waitingForEmail: true }, { merge: true })
        .then(() => {
          console.log(`Updated programmer collection with email ${this.emailAddress}`)
          console.log(`Sign in link sent successfully`);
          window.localStorage.setItem('emailForSignIn', this.emailAddress);
          window.localStorage.setItem('firstName', this.firstName);
          window.localStorage.setItem('lastName', this.lastName)
          router.push('reset-password');
        })
        .catch(error => {
          console.error(`Failed to add player for email: ${this.emailAddress}. Details: ${error.message}`)
        })

    }
  },
  components: {
    Header
  },
}
</script>
