<template>
    <div class="" style="height: 170px; width: 170px;">
        <a href="/user-profile"><img class="absolute bottom-0 right-0"  :src="require('@/images/game/buttons/button-exit.svg')" /></a>
    </div>
</template>

<script>

export default {
  name: 'ExitButton',
  components: {
  },
}
</script>
