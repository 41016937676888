<template>
  <GameModal :showModal="showModal" :dismissFunc="onDismiss" :modalBackgroundUrl="require('@/images/game/ui/bg-glitch.svg')">
  <template v-slot:logo>
    <img class="mx-auto w-40 -mt-2" :src="require('@/images/game/buttons/button-achievements.svg')"/>
  </template>
  <template v-slot:content>
    <h3 class="flex-grow h3 uppercase text-center">[Achievement Earned]</h3>
    <div class="flex-grow justify-self-center">
      <h2 class="h2 text-center">"{{ title }}"</h2>
    </div>
    <p class="pb-2 text-lg text-center justify-self-end">Brought to you by...</p>
    <div class="flex flex-row justify-center place-items-center">
        <SponsorLogo class="mx-4" :sponsorId="leftSponsor" :smallHeight="true" />
        <SponsorLogo class="mx-4" :sponsorId="rightSponsor" :smallHeight="true" />
    </div>
  </template>
  </GameModal>
</template>

<script>
import GameModal from './GameModal.vue'
import SponsorLogo from '../GameSponsorLogo.vue'

export default {
  name: 'AchievementModal',
  props: ['showModal', 'title', 'leftSponsor', 'rightSponsor'],
  inject: ['sponsors'],
  methods: {
    onDismiss() {
      // console.log('Dismiss achievement modal')
      this.$parent.hideAchievementModal()
    }
  },
  components: {
    GameModal,
    SponsorLogo,
  },
}
</script>
