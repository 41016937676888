<template>
  <section class="relative">

    <!-- Section background (needs .relative class on parent and next sibling elements) -->*
    <!-- <div class="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 pointer-events-none" aria-hidden="true"></div> -->
    <!-- <div class="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 transform translate-y-1/2"></div> -->

    <div class="relative max-w-6xl mx-auto p-4 sm:px-6">
        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center mt-12">          
            <h2 class="h2" data-aos="zoom-y-out">Charities</h2>
            <p class="mt-2 py-2 text-md text-center text-gray-600 mx-auto" data-aos="zoom-y-out">The more people who play the game, the more money that will be donated! Our benefiting charities are the Employee Disaster Relief Fund, Feeding America, and Save the Children.</p>

            <p class="font-extrabold text-lg text-left py-2 mt-12">Employee Disaster Relief Fund</p>
            <p class="my-2 text-gray-500 text-left">The employee relief fund’s charitable purpose is to serve as an employee assistance fund. This purpose is accomplished by collecting funds from the public and disbursing them to needy employees, former employees, or members of their immediate families of AT&T who have been affected by hardship due to an unexpected event beyond their control. Such an event includes disasters, extended illness/injury, death, domestic violence and other unexpected emergencies. This assistance is intended to help the affected individual meet short-term needs including amounts to help pay for essential expenses, such as housing, utilities, food, clothing, and other basic necessities. The fund may also provide long-term assistance on a case-by-case basis for natural disaster, fire or flood that totally destroys an employees’ residence. </p>

            <p class="font-extrabold text-lg text-left py-2 mt-6">Feeding America</p>
            <p class="my-2  text-gray-500 text-left">Our mission is to feed America's hungry through a nationwide network of member food banks and engage the country in the fight to end hunger.</p>

            <p class="font-extrabold text-lg text-left py-2 mt-6">Save the Children</p>
            <p class="my-2 text-gray-500 text-left">Save the Children believes every child deserves a future. In the United States and around the world, we work every day to give children a healthy start in life, the opportunity to learn and protection from harm. When crisis strikes, and children are most vulnerable, we are always among the first to respond and the last to leave. We ensure children’s unique needs are met and their voices are heard. We deliver lasting results for millions of children, including those hardest to reach.</p>

        </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CharityInfo',
}
</script>