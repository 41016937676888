<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import AOS from 'aos'
import Sticky from 'sticky-js'
import { focusHandling } from 'cruip-js-toolkit'
import SponsorList from './game/sponsors.js';
import { auth, playerCollection } from './firebase.js'

export default {
  name: 'ProgrammerFair',
  mounted() {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    })
    // console.log(`InGame: ${window.location.pathname == '/game/'}`)
    // console.log(`Auth is ${auth.currentUser}`)
    if (auth.currentUser) {
      const playerDocument = playerCollection.doc(auth.currentUser.email)
      if (playerDocument) {
        // console.log(`Fetched player document ${playerDocument}`)
        playerDocument.set({ playing: window.location.pathname == '/game/' }, { merge: true })
          .then(() => {
            // console.log(`Updated playing=${window.location.pathname == '/game/'} status for ${auth.currentUser.email}`)
          })
          .catch((error) => {
            console.error(`Failed to updated playing=true status for ${auth.currentUser.email}. Details: ${error.message}`)
          })
      } else {
        console.error(`Failed to find document for player: ${auth.currentUser.email}`)
      }
    }
    // eslint-disable-next-line no-unused-vars
    const sticky = new Sticky('[data-sticky]');    
    focusHandling()
    // Route change
    if (this.$router) {
      this.$watch('$route', () => {
        // eslint-disable-next-line no-unused-vars
        const sticky = new Sticky('[data-sticky]'); 
        focusHandling('outline')
      });
    }    
  },
  provide: {
    sponsors: SponsorList["sponsors"],
  }
}
</script>
