<template>
  <GameModal :showModal="showModal" :dismissFunc="onDismiss" :modalBackgroundUrl="require('@/images/game/ui/bg-modal-x.svg')">
    <template v-slot:logo>
      <img class="mx-auto w-32" :src="require('@/images/CircleLogo/' + logoUrl + '.png')"/>
    </template>
    <template v-slot:content>
      <iframe v-if="videoId" class="mx-auto h-64" :src="videoSrc" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
      <SponsorLogo class="mx-auto" v-if="!videoId" :sponsorId="sponsorId" :isSquare="false" :smallHeight="true" />
      <p v-if="description" class="flex-grow text-sm -mx-2 py-6 leading-relaxed" style="white-space: pre-wrap;">{{ description }}</p>
      <p v-if="!description" class="flex-grow text-sm text-red py-6 leading-relaxed text-center uppercase">No Description Found!</p>
    </template>
  </GameModal>
</template>

<script>
import GameModal from './GameModal.vue'
import SponsorLogo from '../GameSponsorLogo.vue'

export default {
  name: 'InfoModal',
  props: ['showModal', 'description', 'logoUrl', 'videoId', 'sponsorId'],
  inject: ['hideSponsorInfo'],
  data() {
    return { hiding: false }
  },
  computed: {
    style() {
      return { 
        'background': 'center url(' + require('@/images/game/ui/bg-modal-x.svg') + ') no-repeat',
        'background-size': '1020px 820px',
        'height': 820 + 'px',
      }
    },
    videoSrc() {
      return 'https://player.vimeo.com/video/' + this.videoId
    }
  },
  methods: {
    onDismiss() {
      // console.log('dismiss info')
      this.$parent.$data.showInfoModal = false
      // this.hideSponsorInfo()
    }
  },
  components: {
    GameModal,
    SponsorLogo,
  },
}
</script>
