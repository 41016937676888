<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

        <!-- Page sections -->
        <AccountHero />
        <Achievements :progressGlitch="progressGlitch" :progressGM="progressGM" :progressHacker="progressHacker" :progressPower="progressPower" :progressOverload="progressOverload" :progressMainStage="progressMainStage"/>
        <!-- <RecentlyViewed /> -->
    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import AccountHero from './../partials/AccountHero.vue'
import Achievements from './../partials/Achievements.vue'
// import RecentlyViewed from './../partials/RecentlyViewed.vue'
import Footer from './../partials/Footer.vue'
import { playerCollection } from '../firebase.js'

export default {
  name: 'UserProfile',
  computed: {
    progressGlitch() {
      if (!this.playerData) {
        return 0
      }
      return this.playerData.glitchesRevealed || 0
    },
    progressGM() {
      if (!this.playerData) {
        return 0
      }
      return this.playerData.miniGamesRevealed || 0
    },
    progressHacker() {
      if (!this.playerData) {
        return 0
      }
      return this.playerData.zonesCompleted || 0
    },
    progressPower() {
      if (!this.playerData) {
        return 0
      }
      return this.playerData.spacesRevealed || 0
    },
    progressOverload() {
      if (!this.playerData) {
        return 0
      }
      return this.progressPower + this.progressMainStage
    },
    progressMainStage() {
      if (!this.playerData) {
        return 0
      }
      return this.playerData.mainStageClicked || 0
    }
  },
  data() {
    return { playerData: null }
  },
  inject: ['currentUser'],
  components: {
    Header,
    AccountHero,
    Achievements,
    // RecentlyViewed,
    Footer,
  },
  mounted() {
    this.fetchAchievementProgress()
  },
  methods: {
    fetchAchievementProgress() {
      if (!this.currentUser.email) return;

      playerCollection.doc(this.currentUser.email).get()
        .then((playerSnapshot) => {
          console.dir(playerSnapshot)
          if (playerSnapshot.exists) {
            // const playerData = playerSnapshot.data()
            this.playerData = playerSnapshot.data()
            console.dir(this.playerData)
          }
        })
        .catch(error => {
          console.error(`Failed to fetch player snapshot for email: ${this.currentUser.email}. Details: ${error.message}`)
        })
    }
  }
}
</script>
