<template>
<section>
    
    <div class="max-w-5xl mx-auto sm:px-6">
        <h2 class="h2 pb-4">Dashboard</h2>

        <div class="flex justify-between items-center py-6 border-t border-b border-gray-100">
            <div class="flex-grow">
                <p>Category</p>
            </div>
            <div class="flex-none w-24"><p class="text-center">Current</p></div>
            <div class="flex-none w-24"><p class="text-center">Total</p></div>
        </div>
        <div class="flex justify-between items-center py-6 border-b border-gray-100">
            <div class="flex-grow">
                <p class="text-sm underline">Active users</p>
                <p class="text-sm">Number of AT&amp;T employees in the event</p>
            </div>
            <div class="flex-none w-24"><p class="text-base text-center tracking-wide text-blue-600 font-bold">{{ activePlayers }}</p></div>
            <div class="flex-none w-24"><p class="text-base text-center tracking-wide">{{ totalPlayers }}</p></div>
        </div>

        <div class="flex justify-between items-center py-6 border-b border-gray-100">
            <div class="flex-grow">
                <p class="text-sm underline">Company profile views</p>
                <p class="text-sm">Number of interactions with your company or product’s game tile</p>
            </div>
            <div class="flex-none w-24"><p class="text-base text-center tracking-wide text-blue-600 font-bold">&mdash;</p></div>
            <div class="flex-none w-24"><p class="text-base text-center tracking-wide">{{ totalInteractions }}</p></div>
        </div>

        <!-- <div class="flex justify-between items-center py-6 border-b border-gray-100">
            <div class="flex-grow">
                <p class="text-sm underline">Interested users</p>
                <p class="text-sm">Number of users who have indicated that they would like more information about your company or product</p>
            </div>
            <div class="flex-none w-24"><p class="text-base text-center tracking-wide">&mdash;</p></div>
            <div class="flex-none w-24"><p class="text-base text-center tracking-wide text-blue-600 font-bold">6</p></div>
        </div> -->

        <!-- <div class="flex justify-between items-center py-6 border-b border-gray-100">
            <div class="flex-grow">
                <p class="text-sm underline">Website views</p>
                <p class="text-sm">Number of users who viewed your website from your company profile</p>
            </div>
            <div class="flex-none w-24"><p class="text-base text-center tracking-wide">&mdash;</p></div>
            <div class="flex-none w-24"><p class="text-base text-center tracking-wide text-blue-600 font-bold">251</p></div>
        </div> -->
    </div>
</section>          
</template>

<script>
import { playerCollection, programmerCollection } from '../firebase.js'
export default {
  name: 'Achievements',
  props: ['sponsorId'],
  inject: ['currentUser'],
  data() {
      return { activePlayerCount: 0, totalPlayerCount: 0, interactionCount: 0 }
  },
  components: {
  },
  computed: {
      activePlayers() {
          return this.activePlayerCount > 0 ? this.activePlayerCount : '—'
      },
      totalPlayers() {
          return this.totalPlayerCount > 0 ? this.totalPlayerCount : '—'
      },
      totalInteractions() {
          return this.interactionCount > 0 ? this.interactionCount : '—'
      }
  },
  mounted() {
      playerCollection.where('played', '==', true).get()
        .then(snapshot => {
            // console.log(`Found ${snapshot.size} total players`)
            this.totalPlayerCount = snapshot.size
        })
        .catch(error => {
            console.error(`Failed to query active player count. Details: ${error.message}`)
        })
    
      playerCollection.where('playing', '==', true).get()
        .then(snapshot => {
            // console.log(`Found ${snapshot.size} active players`)
            this.activePlayerCount = snapshot.size
        })
        .catch(error => {
            console.error(`Failed to query active player count. Details: ${error.message}`)
        })  
  },
  watch: {
      sponsorId(newSponsorId) {
          programmerCollection.doc(newSponsorId).get()
            .then((programmerSnap) => {
                if (programmerSnap.exists) {
                    const programmerData = programmerSnap.data()
                    this.interactionCount = programmerData.interactions || 0
                }
            })
      }
  },
}
</script>