<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">
      <TermsText />
    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import TermsText from './../partials/TermsText.vue'
import Footer from './../partials/Footer.vue'

export default {
  name: 'Terms',
  components: {
    Header,
    TermsText,
    Footer,
  },
}
</script>
