<template>
  <GameModal :showModal="showModal" :dismissFunc="onDismiss" :modalBackgroundUrl="require('@/images/game/ui/bg-glitch.svg')">
    <template v-slot:logo>
      <img class="-mt-2" :src="require('@/images/game/buttons/button-achievements.svg')" />
      
    </template>
    <template v-slot:content>
      <div class="flex flex-col justify-between h-full text-center">
        <div class="flex flex-col">
          <h3 class="h3 uppercase tracking-wider">[Data Key Unlocked]</h3>
          <p class="text-lg uppercase tracking-wider">{{ keyId }} Zone</p>
        </div>

        <img class="max-w-xs mx-auto h-64" :src="keyImageUrl" />

        <button v-on:click="onDismiss" class="btn btn-sm mx-auto px-8 rounded-full hover:bg-game-blue hover:text-game-dark bg-game-blue-light text-game-dark text-lg uppercase">
          Collect Key
        </button>
      </div>
    </template>
  </GameModal>
</template>

<script>
import GameModal from './GameModal.vue'

export default {
  name: 'KeyModal',
  props: ['showModal', 'keyId'],
  data() {
    return { 
      hiding: false,
      keyNames: ['Family', 'Sports', 'Entertainment', 'Movie', 'Family', 'International'],
    }
  },
  computed: {
    keyImageUrl() {
      return require('@/images/game/ui/' + this.keyId + ' Key.png')
    },
  },
  methods: {
    onDismiss() {
      this.$parent.$data.zonesCompleted++
      this.$parent.$data.showDataKeyModal = false
    }
  },
  components: {
    GameModal,
  },
}
</script>
