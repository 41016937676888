<template>
	<div v-on:click.prevent.self class="transition transition-transform absolute z-20" :style="style">
		<img v-on:click.prevent.self v-if="avatarIndex == 0" :style="imgStyle" :src="require('@/images/game/avatar/Avatar_Blue.svg')">
		<img v-on:click.prevent.self v-if="avatarIndex == 0" class="absolute top-0 left-0" :class="{'animate-ping': !moving}" :style="imgStyle" :src="require('@/images/game/avatar/Avatar_Blue.svg')">

		<img v-if="avatarIndex == 1" :style="imgStyle" :src="require('@/images/game/avatar/Avatar_Purple.svg')">
		<img v-if="avatarIndex == 1" class="absolute top-0 left-0" :class="{'animate-ping': !moving}" :style="imgStyle" :src="require('@/images/game/avatar/Avatar_Purple.svg')">

		<img v-if="avatarIndex == 2" :style="imgStyle" :src="require('@/images/game/avatar/Avatar_Orange.svg')">
		<img v-if="avatarIndex == 2" class="absolute top-0 left-0" :class="{'animate-ping': !moving}" :style="imgStyle" :src="require('@/images/game/avatar/Avatar_Orange.svg')">

		<img v-if="avatarIndex == 3" :style="imgStyle" :src="require('@/images/game/avatar/Avatar_Green.svg')">
		<img v-if="avatarIndex == 3" class="absolute top-0 left-0" :class="{'animate-ping': !moving}" :style="imgStyle" :src="require('@/images/game/avatar/Avatar_Green.svg')">
		
		<img v-if="avatarIndex == 4" :style="imgStyle" :src="require('@/images/game/avatar/Avatar_Teal.svg')">
		<img v-if="avatarIndex == 4" class="absolute top-0 left-0" :class="{'animate-ping': !moving}" :style="imgStyle" :src="require('@/images/game/avatar/Avatar_Teal.svg')">

		<img v-on:click.prevent.self v-if="avatarIndex == 5" :style="imgStyle" :src="require('@/images/game/avatar/Avatar_Grey.svg')">
		<img v-on:click.prevent.self v-if="avatarIndex == 5" class="absolute top-0 left-0" :class="{'animate-ping': !moving}" :style="imgStyle" :src="require('@/images/game/avatar/Avatar_Grey.svg')">
	</div>
</template>

<script>

export default {
  name: 'Avatar',
  data() {
		return { moving: false, x: 2600, y: 1200, direction: 0, flipped: false }
	},
	props: ['avatarIndex', 'currentSpace'],
	computed: {
		imgStyle() {
			return {
				width: 120 + 'px',
				height: 120 + 'px',
			}
		},
		style() {
			return {
				top: this.y + this.offsetForOrientation.top + 48 + 'px',
				left: this.x + this.offsetForOrientation.left + 'px',
				width: 120 + 'px',
				height: 120 + 'px',
			}
		},
		offsetForOrientation() {
			if (this.direction == 0) {
				// Top left
				return {
					top: 68,
					left: 66,
				}
			} else if (this.direction == 1) {
				// top right
				return {
					top: 68,
					left: 26,
				}
			} else if (this.direction == 2) {
				// bottom left
				return {
					top: 28,
					left: 66,
				}
			} else if (this.direction == 3) {
				// bottom right
				return {
					top: 28,
					left: 26,
				}
			} else if (this.direction == 4) {
				// Vertical
				if (this.flipped) {
					return {
						top: 40,
						left: 32, 
					}
				} else {
					return {
						top: 44,
						left: 32, 
					}
				}
			} else if (this.direction == 5) {
				// Horizontal
				if (this.flipped) {
					return {
						top: 24,
						left: 44, 
					}
				} else {
					return {
						top: 24,
						left: 42, 
					}
				}
			}
			return { top: 0, left: 0 }	
		},
		width() {
			return 110
		},
		height() {
			return 110
		},
	},
  components: {
  },
  methods: {

  }
}
</script>
