<template>
    <section v-on:click="listVisible = !listVisible" class="max-w-3xl w-96 z-20">
        <img v-if="!listVisible" class="z-20 cursor-pointer" :src="require('@/images/game/buttons/button-search.svg')" />
        <div v-if="listVisible" class="mt-4" :style="{ 'border-image': 'url(' + background + ') 64 fill' }" style="border-image-slice: 64 fill; border-image-width: 64px 64px; border-width: 64px;">
            <div class="-mx-4 -my-2">
                <p class="mb-2 text-2xl px-12 font-bold uppercase text-center text-game-blue tracking-wider">[Browse Programmers]</p>
                <div class="-ml-4 overflow-auto h-64 pl-4 pr-2 mb-2 mt-1">
                    <SponsorRow 
                        v-for="sponsor in sponsors" 
                            v-bind:key="sponsor.id" 
                            v-bind:short_name="sponsor.short_name"
                            v-bind:tier="sponsor.tier"
                            v-bind:sponsorId="sponsor.id"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import SponsorRow from './GameSponsorRow.vue'

export default {
  name: 'SponsorList',
  data: () => {
      return { 
          listVisible: false,
          background: require('@/images/game/ui/bg-panel.svg'),
        }
  },
  inject: ['sponsors'],
  components: {
      SponsorRow,
  },
}
</script>
