<template>
  <GameModal :showModal="showModal" :dismissFunc="onDismiss" :modalBackgroundUrl="require('@/images/game/ui/bg-glitch-no-x.svg')">
    <template v-slot:logo>
      <img class="mx-auto w-32 -mt-1" :src="require('@/images/game/buttons/button-achievements.svg')"/>
    </template>
    <template v-slot:content>
      <div class="flex flex-col justify-between h-full text-center">
        <h2 class="h2 uppercase">[You Finished the Game!]</h2>
        <img class="mx-auto" :src="require('@/images/game/fireworks.gif')" style="width: 250px;" />
        <p class="text-xl py-2 leading-relaxed">AT&amp;T will donate ${{ charityTotal }} to charity on your behalf!</p>
      </div>
    </template>
  </GameModal>
</template>

<script>
import GameModal from './GameModal.vue'

export default {
  name: 'FinishedGameModal',
  props: ['showModal', 'description', 'charityTotal'],
  inject: ['hideSponsorInfo'],
  data() {
    return { 
      hiding: false,
    }
  },
  computed: {

  },
  methods: {
    onDismiss() {
      // console.log('dismiss info')
      // this.$parent.$data.showTriviaModal = false
    }
  },
  components: {
    GameModal,
  },
}
</script>
