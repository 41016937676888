<template>
      <section class="relative transform scale-125" style="transform: scale(1.15) translateY(-200px) translateX(-200px)">

        <div class="relative w-full">
          <img class="absolute" :src="require('@/images/game/ui/bg-large-panel.svg')" style="left: 2102px; top: 1010px;">
          <img class="absolute" :src="require('@/images/game/board/Screensaver.png')" style="left: 2280px; top: 1230px; width: 900px;">
          <p class="absolute text-xl text-center text-game-blue-dark" style="left: 2280px; top: 1650px; width: 900px;">click top sponsor logo to view video</p>
          
          <!-- 2280 1230 -->
          <div ref="mainStageWrapper" class="relative" :style="{ borderImage: require('@/images/game/ui/bg-large-panel.svg') }">
            <iframe v-if="mainStageVideoId" ref="mainStageVideo" id="main-stage-video" class="absolute z-10" :src="mainStageVideoUrl" style="left: 2280px; top: 1230px;" width="900" height="500" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
        </div>

        <div class="flex flex-col absolute" style="top: 1080px; left: 1960px;">
          <SponsorLogo v-on:click.native="clickedMainStageSponsor('AMC')" :sponsorId="'AMC'" :isSquare="false"  :selectedSponsorId="selectedSponsorId" class="cursor-pointer hover:border-game-blue w-40 h-40 rounded-full"/>
          <SponsorLogo v-on:click.native="clickedMainStageSponsor('CMF')" :sponsorId="'CMF'" :isSquare="false"    :selectedSponsorId="selectedSponsorId" class="cursor-pointer w-40 h-40 border-game-blue" />
          <SponsorLogo v-on:click.native="clickedMainStageSponsor('DI')" :sponsorId="'DI'" :isSquare="false"   :selectedSponsorId="selectedSponsorId" class="cursor-pointer w-40 h-40 border-game-blue" />
          <SponsorLogo v-on:click.native="clickedMainStageSponsor('ESPN')" :sponsorId="'ESPN'" :isSquare="false"  :selectedSponsorId="selectedSponsorId"  class="cursor-pointer w-40 h-40 border-game-blue" />
        </div>

        <div class="flex flex-col absolute" style="top: 1080px; left: 3320px;">
          <SponsorLogo v-on:click.native="clickedMainStageSponsor('HBO')" :sponsorId="'HBO'" :isSquare="false" :selectedSponsorId="selectedSponsorId" class="cursor-pointer w-40 h-40 border-game-blue" />
          <SponsorLogo v-on:click.native="clickedMainStageSponsor('VIA')" :sponsorId="'VIA'" :isSquare="false" :selectedSponsorId="selectedSponsorId"  class="cursor-pointer w-40 h-40 border-game-blue" />
          <SponsorLogo v-on:click.native="clickedMainStageSponsor('SHOWTIME')" :sponsorId="'SHOWTIME'" :isSquare="false" :selectedSponsorId="selectedSponsorId"  class="cursor-pointer w-40 h-40 border-game-blue" />
          <SponsorLogo v-on:click.native="clickedMainStageSponsor('STARZ')" :sponsorId="'STARZ'" :isSquare="false"  :selectedSponsorId="selectedSponsorId"  class="cursor-pointer w-40 h-40 border-game-blue" />
        </div>
    </section>
</template>

<script>
import SponsorLogo from './GameSponsorLogo.vue'

export default {
  name: 'GameVideoPlayer',
  inject: ['sponsors'],
  data() {
    return { 
      selectedSponsorId: '',
      mainStageVideoId: '',
    }
  },
  components: {
    SponsorLogo,
  },
  computed: {
    logoStyle() {
        return {}
    },
    mainStageVideoUrl() {
      return `https://player.vimeo.com/video/${this.mainStageVideoId}`
    }
  },
  methods: {
    clickedMainStageSponsor(sponsorId) {
      const sponsor = this.sponsors.find(sponsor => sponsor.id == sponsorId);
      if (!sponsor) {
        return
      }
      this.selectedSponsorId = sponsor.id
      // console.log(`New selected sponsor id: ${this.selectedSponsorId}`)
      // console.log(`Changing main stage player to ${sponsor.mainStageVideoId}`)
      this.mainStageVideoId = sponsor.mainStageVideoId
      this.$parent.$parent.$data.mainStageClicked++
    }
  }
}
</script>
