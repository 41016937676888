import Vue from 'vue'
import VueRouter from 'vue-router'
import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)
// Add font-awesome support
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckDouble, faExternalLinkAlt, faHatWizard, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faExternalLinkAlt, faStar, faCheckDouble, faHatWizard)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)

import App from './App.vue'

import { auth } from './firebase';

import '@/css/style.scss';

Vue.use(VueRouter)
import Game from './game/Game.vue'
import Home from './pages/Home.vue'
import Dashboard from './pages/Dashboard.vue'
import UserProfile from './pages/UserProfile.vue'
import SignIn from './pages/SignIn.vue'
import FinishSignIn from './pages/FinishSignIn.vue'
import QA from './pages/QA.vue'
import VendorSignIn from './pages/VendorSignIn.vue'
import VendorSignUp from './pages/VendorSignUp.vue'
import Terms from './pages/Terms.vue'
import Privacy from './pages/Privacy.vue'
import ResetPassword from './pages/ResetPassword.vue'
import PageNotFound from './pages/PageNotFound.vue'
import Database from './pages/Database.vue'
import AdminMagicLink from './pages/AdminMagicLink.vue'

const routes = [
  {
    path: '/',
    component: Home,
    meta: {
      title: 'AT&T Programmer Fair - Home'
    }
  },
  {
    path: '/admin/db',
    component: Database,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/links',
    component: AdminMagicLink,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/game',
    component: Game,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/support',
    component: QA
  },
  {
    path: '/signin',
    component: SignIn
  },
  {
    path: '/programmer-signin',
    component: VendorSignIn
  },
  {
    path: '/programmer-signup',
    component: VendorSignUp
  },
  {
    path: '/finish-sign-in',
    component: FinishSignIn
  },
  {
    path: '/reset-password',
    component: ResetPassword
  },              
  {
    path: '/terms',
    component: Terms
  }, 
  {
    path: '/privacy',
    component: Privacy
  },
  {
    path: '/user-profile',
    component: UserProfile,
    meta: {
      requiresAuth: true,
    }
  },           
  {
    path: '/dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/reset-password',
    component: ResetPassword
  },      
  {
    path: '*',
    component: PageNotFound
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to) {
    return new Promise(() => {
      if (to.hash) {
        window.scroll({ top: 0 })
      } else {
        document.querySelector('html').style.scrollBehavior = 'auto'
        window.scroll({ top: 0 })
        document.querySelector('html').style.scrollBehavior = ''
      }
    })
  }  
})

router.beforeEach((to, from, next) => {
  const currentUser = auth.currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) {
    next('signin');
  } else {
    next();
  }
});

Vue.config.productionTip = false


var app;
auth.onAuthStateChanged(function(user) {
  if (!app) {
    new Vue({
      router,
      render: h => h(App),
      provide: {
        currentUser: user,
      },
    }).$mount('#app');
  }
});
