<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <section class="bg-gradient-to-b from-gray-100 to-white">
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <div class="pt-32 pb-12 md:pt-40 md:pb-20">

            <!-- Page header -->
            <div class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
              <h1 class="h1">{{ errorMessage ? "We're Sorry!" : 'Finishing Sign In...'}}</h1>
              <p class="py-4" v-if="errorMessage">{{ errorMessage }}</p>
            </div>

          </div>
        </div>
      </section>

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import { auth, playerCollection, programmerCollection }  from './../firebase'

export default {
    name: 'SignIn',
    inject: ['currentUser'],
    components: {
        Header
    },
    data() {
        return { 
            firstName: '',
            lastName: '',
            emailAddress: '',
            errorMessage: null,
        };
    },
    methods: {
      updateProgrammerStatus() {
        programmerCollection.where('emails', 'array-contains', this.emailAddress).get()
          .then(() => {
            // const isProgrammer = !snapshot.empty
            const isProgrammer = (this.emailAddress.toLowerCase().endsWith('att.com') == false)
            playerCollection.doc(this.emailAddress).set({ playing: false, programmer: isProgrammer, waitingForEmail: false }, { merge: true })
              .then(() => {
                console.log(`Set initial player document successfully.`)
                if (!this.$root.$router) {
                  setTimeout(() => { this.$root.$router.push(isProgrammer ? '/dashboard' : '/user-profile') }, 6000)
                } else {
                  this.$root.$router.push(isProgrammer ? '/dashboard' : '/user-profile')
                }
                
                // auth.currentUser.reload()
                //   .then(() => {
                //     console.log(`Reloaded current user authentication`)
                    
                //   })
                //   .catch((error) => {
                //     console.error(`Failed to reload current user auth. Details: ${error.message}`)
                //   });
                
              })
          })
          .catch(error => {
            console.error(`Failed to query programmers list for email: ${this.emailAddress}. Details: ${error.message}`)
          })
      }
    },
    mounted() {
      // auth.onAuthStateChanged((user) => {
      //   if (user) {
      //     // User is signed in, see docs for a list of available properties
      //     // https://firebase.google.com/docs/reference/js/firebase.User
      //     // var uid = user.uid;
      //     // ...
      //     console.log('logged in')
      //     this.updateProgrammerStatus()
      //   } else {
      //     // User is signed out
      //     // ...
      //     console.log('signed out')
      //   }
      // });
      this.emailAddress = window.localStorage.getItem('emailForSignIn');
      //  const actionCode = this.$router.query.oobCode
      // const params = new URLSearchParams(window.location.search)
      //  //console.dir(params)
      // auth.applyActionCode(params.get('oobCode')).then(() => {
      //       // Email address has been verified.
      //       console.log('Applied action code')
              
      //       auth.getRedirectResult().then((userResult) => {
      //         console.log('User result')
      //         console.dir(userResult)
      //         if (userResult) {
      //           // TODO: Display a confirmation message to the user.
      //           // You could also provide the user with a link back to the app.
      //           console.log(`auth.currentUser ${auth.currentUser}`)
      //           console.log(`currentUser ${this.currentUser}`)
      //           console.log(`userResult ${userResult.user}`)

      //           // this.updateProgrammerStatus()
      //         }
      //       })
      //       .catch((error) => {
      //         console.error(`Failed to getRedirectResult. Details: ${error.message}`)
      //       })
          

      //         // TODO: If a continue URL is available, display a button which on
      //         // click redirects the user back to the app via continueUrl with
      //         // additional state determined from that URL's parameters.
      //       }).catch(function(error) {
      //         // Code is invalid or expired. Ask the user to verify their email address
      //         // again.
      //         console.error(`Failed to apply action code. Details: ${error.message}`)
      //       });
        // Confirm the link is a sign-in with email link.
        if (auth.isSignInWithEmailLink(window.location.href) && !auth.currentUser) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // // Get the email if available. This should be available if the user completes
            // // the flow on the same device where they started it.
            // var email = window.localStorage.getItem('emailForSignIn');
            // if (!email) {
            //     // User opened the link on a different device. To prevent session fixation
            //     // attacks, ask the user to provide the associated email again. For example:
            //     email = window.prompt('Please provide your email for confirmation');
            // }
            
            // const context = this;

            // The client SDK will parse the code from the link for you.
            auth.signInWithEmailLink(this.emailAddress, window.location.href)
                .then((result) => {
                    const user = result.user;
                    console.log(`Signed in as ${result.additionalUserInfo.isNewUser ? 'NEW User' : 'Existing User'}. User: ${user}.`);

                    // Update profile with display name here
                    // const firstName = window.localStorage.getItem('firstName')
                    // const lastName = window.localStorage.getItem('lastName')
                    // playerCollection.doc(this.emailAddress).set({ waitingForEmail: false }, { merge: true })
                    

                    this.updateProgrammerStatus()
                    
                })
                .catch((error) => {
                    console.error(`Failed to sign in. Code ${error.code}. Details: ${error.message}`);
                    this.errorMessage = error.message
                });




        } else {
          if (auth.currentUser) {
            console.log('Already logged in')
            this.updateProgrammerStatus()
          } else {
            console.log('Not sign in email link')
          }
          
        }

        // } else {
          
        //   if (this.emailAddress) {
        //     console.log('Already authorized')
        //     this.updateProgrammerStatus()
        //   } else {
        //     console.log('Wasn\'t valid sign in link')
        //     const actionCode = this.$root.$routery.query.oobCode
        //     auth.applyActionCode(actionCode).then(function() {
        //       // Email address has been verified.
        //       console.log('Applied action code')
        //       this.updateProgrammerStatus()
        //       // TODO: Display a confirmation message to the user.
        //       // You could also provide the user with a link back to the app.

        //       // TODO: If a continue URL is available, display a button which on
        //       // click redirects the user back to the app via continueUrl with
        //       // additional state determined from that URL's parameters.
        //     }).catch(function(error) {
        //       // Code is invalid or expired. Ask the user to verify their email address
        //       // again.
        //       console.error(`Failed to apply action code. Details: ${error.message}`)
        //     });
        //   }
        // }
    },
}
</script>
