<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <section class="bg-gradient-to-b from-gray-100 to-white">
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <div class="pt-32 pb-12 md:pt-40 md:pb-20">

            <!-- Page header -->
            <div class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
              <h1 class="h1">Thank you for helping us make this event possible</h1>
            </div>

            <!-- Form -->
            <div class="max-w-sm mx-auto">
              <form>
                <div class="flex flex-wrap -mx-3 mb-4">
                  <div class="w-full px-3">
                    <label class="block text-gray-800 text-sm font-medium mb-1" for="name">Primary Contact <span class="text-red-600">*</span></label>
                    <input id="name" type="text" class="form-input w-full text-gray-800" placeholder="Enter your name" required />
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-4">
                  <div class="w-full px-3">
                    <label class="block text-gray-800 text-sm font-medium mb-1" for="email">Email <span class="text-red-600">*</span></label>
                    <input id="email" type="email" class="form-input w-full text-gray-800" placeholder="you@yourcompany.com" required />
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-4">
                  <div class="w-full px-3">
                    <label class="block text-gray-800 text-sm font-medium mb-1" for="name">Business Name <span class="text-red-600">*</span></label>
                    <input id="name" type="text" class="form-input w-full text-gray-800" placeholder="ACME Products" required />
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-4">
                  <div class="w-full px-3">
                    <label class="block text-gray-800 text-sm font-medium mb-1" for="name">Business or Product Description <span class="text-red-600">*</span></label>
                    <input id="name" type="textarea" class="form-input w-full text-gray-800" placeholder="You can edit this later" required />
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mt-6">
                  <div class="w-full px-3">
                    <button class="btn text-white bg-blue-600 hover:bg-blue-700 w-full">Sign up as Vendor</button>
                  </div>
                </div>
                <div class="text-sm text-gray-500 text-center mt-3">
                  By creating an account, you agree to the <a class="underline" href="/terms">terms & conditions</a>, and our <a class="underline" href="/privacy">privacy policy</a>.
                </div>
              </form>
              <div class="text-gray-600 text-center mt-6">
                Already registered as a Vendor? <router-link to="/programmer-signin" class="text-blue-600 hover:underline transition duration-150 ease-in-out">Vendor Sign in</router-link>
              </div>
            </div>

          </div>
        </div>
      </section>

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'

export default {
  name: 'VendorSignUp',
  components: {
    Header,
  },
}
</script>
