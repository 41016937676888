<template>
  <GameModal :showModal="showModal" :dismissFunc="hideGlitch" :modalBackgroundUrl="require('@/images/game/ui/bg-glitch.svg')">
    <template v-slot:logo>
    </template>
    <template v-slot:content>
      <div class="flex flex-col mt-24 justify-center h-full text-center">
        <h3 class="h3 uppercase text-center">[{{ title ? title : 'Glitch Detected'}}]</h3>
        <h4 class="h4 uppercase text-center py-24">{{ description }}</h4>
      </div>
    </template>
  </GameModal>
</template>

<script>
import GameModal from './GameModal.vue'

export default {
  name: 'GlitchModal',
  props: ['showModal', 'title', 'description'],
  inject: ['hideGlitch'],
  data() {
    return { 
      hiding: false,
    }
  },
  components: {
    GameModal,
  },
}
</script>
