<template>
    <section class="fixed inset-0 w-screen h-screen z-50 overflow-hidden p-10 bg-game-dark bg-opacity-50 transition transition-opacity duration-500" 
                v-on:click="handleDismiss" 
                v-if="showModal"
                style="backdrop-filter: blur(16px);"
                :class="{'opacity-0': fadingIn || fadingOut, 'opacity-100': visible}">
      <div class="fixed inset-0 text-game-blue" :style="style">
          <div class="flex flex-col h-full justify-between max-w-3xl mt-6 mx-auto p-4 px-16 pb-32">
            <!-- <img class="mx-auto" :src="require('@/images/game/buttons/button-achievements.svg')" /> -->
            <!-- <img class="mx-auto w-32" :src="require('@/images/CircleLogo/' + logoUrl + '.png')"/> -->
            <div class="mx-auto w-96 mt-2 mb-8 z-20">
                <slot name="logo"></slot>
            </div>
            <div class="flex flex-col justify-between h-full mb-2 z-10">
                <slot name="content">
                    <div class="bg-game-blue-light h-16 my-4"></div>
                    <div class="bg-game-blue h-16 my-4"></div>
                    <div class="bg-game-blue-dark h-16 my-4"></div>
                </slot>
            </div>
          </div>
      </div>
    </section>
</template>

<script>
export default {
  name: 'GameModal',
  props: ['showModal', 'content', 'logo', 'dismissable', 'dismissFunc', 'modalBackgroundUrl'],
  inject: ['hideSponsorInfo'],
  data() {
    return { hiding: false, fadingIn: false, fadingOut: false, visible: false }
  },
  watch: {
    showModal(newShow) {
      // console.log(`Show modal status: ${newShow}`)
      if (newShow) {
        this.fadingIn = true
        setTimeout(() => { 
          this.visible = true
        }, 500)
      } else {
        this.visible = false
        this.dismissFunc()
      }
    },
    visible(newVisible) {
      if (newVisible) {
        this.fadingIn = false
      } else {
        this.fadingOut = false
        this.dismissFunc()
      }
    }
  },
  computed: {
    style() {
      return { 
        'background': 'center url(' + this.modalBackgroundUrl + ') no-repeat',
        'background-size': '1020px 820px',
        'height': 820 + 'px',
      }
    },
    videoSrc() {
      return 'https://player.vimeo.com/video/' + this.videoId
    }
  },
  methods: {
      handleDismiss() {
        this.visible = false
        this.dismissFunc()
        return

        // if (this.fadingOut || this.fadingIn) {
        //   return
        // }
          
        // this.fadingOut = true
        // setTimeout(() => { 
        //   this.visible = false
        // }, 500)
      }
  },
  components: {
  },
}
</script>
