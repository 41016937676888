export default { 
    "sponsors": [
        {"id": "AE", "sizzleVideoId": "483266803", "logoName": "AE", "short_name": "A+E Networks", "name": "A&E Networks", "tier": 1, "prizes": ['Nest WiFi Security Camera', 'Nest WiFi Security Cameras', 'Nest WiFi Security Cameras', 'Nest WiFi Security Cameras', 'Nest WiFi Security Cameras'],  "description": "A+E Networks® is a collection of culture brands that includes A&E®, HISTORY®, Lifetime®, LMN™, FYI™, Vice TV and BIOGRAPHY®. We are in the story business and just as the industry and art form have evolved, so have we. Since our founding in 1983 with just two cable channels, we have grown into a multi-platform, multimedia, multi-genre content creator. Our brands have points of view we proudly champion and defend. We got to where we are by being great listeners, innovators and idea movers, specializing in the human experience." },
        {"id": "AMC", "mainStageVideoId": "483255340", "sizzleVideoId": "483255340", "logoName": "AMC", "short_name": "AMC+", "name": "AMC Networks", "tier": 3, "prizes": [],  "description": "AMC+ is AMC Networks new premium subscription bundle featuring an extensive lineup of popular and critically acclaimed original programming from across AMC Networks’ entertainment networks and a number of the company’s targeted streaming services including Shudder, Sundance Now and IFC Films Unlimited, which feature content such as Riviera, Creepshow, A Discovery of Witches and Boyhood. AMC+ also includes a curated premium linear channel found on DirecTV’s channel 557."},
        {"id": "AXS","sizzleVideoId": "483258204", "logoName": "AXStv", "short_name": "AXS TV", "name": "AXS TV & HDNET MOVIES", "tier": 1, "prizes": ['Bose Soundlink Micro Portable Bluetooth Speaker', 'Bose SoundSport Wireless Sports Earbuds', 'Daryl Hall Autographed Poster'],  "description": "AXS TV is a leading music, entertainment and lifestyle channel with cutting-edge music programming you won’t find anywhere else.  From concerts to intimate behind-the-scenes series, AXS TV shows celebrate the artist behind the music.\n\nHDNET MOVIES is the ultimate movie lovers’ channel, featuring uniquely compelling titles licensed from the biggest studios in the world, including award-winning theatrically released films, and Hollywood classics."},
        {"id": "CARA","sizzleVideoId": "483261285", "logoName": "Caracol", "short_name": "Caracol", "name": "Caracol Television", "tier": 1, "prizes": ['$100 Prepaid Debit Card', '$100 Prepaid Debit Card', '$100 Prepaid Debit Card', '$100 Prepaid Debit Card', '$100 Prepaid Debit Card'],  "description": "For  over  half  a  century,  Caracol  Television  has  produced the  most innovated  successful  and  more  viewed  television  shows,  realities  and novellas in Colombia   Caracol  TV has  become  a  true  powerhouse,  expanding its influence beyond    Colombia    through Caracol    International,    A world-class broadcasting channel that reaches over 12 million households all around the world. Our  programming include stop  quality  content such  as novellas, news, entertainment, realities, current affairs and more than 60 weekly hours in simultaneous with Caracol Colombia."},
        {"id": "CN","sizzleVideoId": "483257086", "logoName": "CartoonNetwork", "short_name": "Cartoon Network (WarnerMedia)", "name": "Cartoon Network (Warner Media)", "tier": 3, "prizes": ['HBO Max Apple TV', 'HBO Max Polaroid Snap Touch Black Digital Camera', 'HBO Max Fitbit Versa 2 Watch'], "description": "Teen Titans Go! Craig of the Creek! Total Dramarama! And even MORE in 2021 where kids go to laugh, relax, and be themselves. The fun doesn't stop on Cartoon Network."},
        {"id": "CL", "mainStageVideoId": "483247970", "sizzleVideoId": "483247847", "logoName": "CineLatino", "short_name": "Cinelatino (Hemisphere Media Group)", "name": "Cinelatino (Hemisphere Media Group)", "tier": 1, "prizes": ['Work from Home Kit', 'Work from Home Kit', 'Work from Home Kit', 'Work from Home Kit', 'Work from Home Kit', 'LED Selfie Ring', '5 x 6.5 Photo Video Studio', 'Wireless Ear Buds with Microphone'],  "description": "Leading Spanish-language movie channel offering the widest selection of current blockbuster hits and critically acclaimed movies from Mexico, Latin America, Spain, and the Caribbean."},
        {"id": "OLYM", "sizzleVideoId": "483269086", "logoName": "Olympusat", "short_name": "Cine Mexicano", "name": "Cine Mexicano", "tier": 1, "prizes": ['$100 Amazon gift card'],  "description": "Cine Mexicano is the country’s top-rated Spanish-language movie network featuring commercial-free contemporary Mexican movies, offering an extensive library of fan-favorite titles including adrenaline packed action, drama, comedy, rancheras and thrillers."},
        {"id": "CLEO","sizzleVideoId": "483249118", "logoName": "Cleo", "short_name": "CLEO TV", "name": "TV One/CLEO TV", "tier": 3, "prizes": ['$100 AMEX gift card and CLEO swag', '$100 AMEX gift card and CLEO swag', '$100 AMEX gift card and CLEO swag', '$100 AMEX gift card and CLEO swag', '$100 AMEX gift card and CLEO swag'], "description": "CLEO TV showcases lifestyle and entertaining content for women of color. Derived from the name Cleopatra, one of the most powerful, trendsetting, rule-breaking and iconic women in history, CLEO TV offers quality content that defies negative and cultural stereotypes of today’s modern women.CLEO TV is designed for Millennial and GEN X women who are living life on their own terms. They are modern disruptors, ethnically diverse and tech savvy feminists who watch TV their way. The network delivers a unique and diverse programming mix of short-form and long-form content including travel, home design, cooking, talk shows, movies, docu-series, sitcoms and much more. CLEO TV programming is original, authentic, transformational and honest. CLEO TV is FLAWLESS. FEARLESS. FRESH!"},
        {"id": "CMF", "mainStageVideoId": "486142810", "sizzleVideoId": "483243199", "logoName": "CrownMedia", "short_name": "Crown Media Family Networks", "name": "Crown Media Family Networks", "tier": 3, "prizes": [],  "description": "A 24/7 destination for quality family entertainment, Crown Media Family Networks features an unduplicated  lineup  of  programming  across  3  linear  networks, Hallmark  Channel,  Hallmark Movies & Mysteries, and Hallmark Drama, and SVOD service, Hallmark Movies Now. Rooted in the legacy of the Hallmark brand, Crown Media’s unique content helps viewers celebrate holidays, seasons, and life’s special occasions."},
        {"id": "DI", "mainStageVideoId": "487416290", "sizzleVideoId": "483242214", "logoName": "Discovery", "short_name": "Discovery Inc.", "name": "Discovery Inc.", "tier": 3, "prizes": [], "description": "Discovery, Inc. is a global leader in real life entertainment, serving a passionate audience of superfans around the world with content that inspires, informs and entertains. Discovery delivers over 8,000 hours of original programming each year and has category leadership across deeply loved content genres around the world. Discovery’s portfolio of premium brands includes Discovery Channel, HGTV, Food Network, TLC, Investigation Discovery, Travel Channel, MotorTrend, Animal Planet, Science Channel, OWN: Oprah Winfrey Network and the forthcoming JV with Chip and Joanna Gaines, Magnolia."},
        {"id": "ESW","sizzleVideoId": "483265450", "logoName": "EntertainmentStudios", "short_name": "Entertainment Studios Networks", "name": "Entertainment Studios/Weather", "tier": 1, "prizes": ['NOAA Emergency Radio'],  "description": "ENTERTAINMENT STUDIOS NETWORKS is a full portfolio of lifestyle channels with content that informs and entertains more than 150 million subscribers every day. Byron Allen, founder, chairman and CEO of ALLEN MEDIA GROUP, LLC. has focused his production prowess on supplying the ES Networks with thousands of hours of original, compelling, family and advertiser-friendly programming. With our premiere network, THE WEATHER CHANNEL, the ENTERTAINMENT STUDIOS NETWORKS leads national climate and weather event coverage that informs our audience and helps to save lives."},
        {"id": "EPIX","sizzleVideoId": "483259658", "logoName": "Epix", "short_name": "EPIX", "name": "EPIX", "tier": 3, "prizes": ['EPIX Apple iPods', 'EPIX Apple iPods'],  "description": "EPIX. Have we got a story for you. Watch exclusive, critically-acclaimed original series including Pennyworth, Britannia, and Godfather of Harlem, plus 1000s of Hollywood movies, and more — all uncut and commercial-free. With one subscription, experience it all — On TV + Online + On Demand + On the Go with the EPIX app."},
        {"id": "ESPN", "mainStageVideoId": "487440203", "sizzleVideoId": "487440446", "logoName": "ESPN", "short_name": "ESPN", "name": "ESPN (The Walt Disney Company)", "tier": 3, "description": "ESPN, Inc. is the leading multinational, multimedia sports entertainment brand featuring the broadest portfolio of multimedia sports assets."},
        {"id": "EWTN","sizzleVideoId": "483266286", "logoName": "EWTN", "short_name": "EWTN Global Catholic Network", "name": "EWTN Global Catholic Network", "tier": 1, "prizes": ['Gold Cross Necklace', 'Nativity Set', 'Nativity Set', 'Jesus Statue', 'Jesus Statue', ],  "description": "EWTN's mission is to communicate the teachings and the beauty of the Catholic Church and to help people grow in their love and understanding of God and His infinite mercy. EWTN utilizes a worldwide group of television networks, radio stations, newspaper and extensive internet site to share this message. Original programming includes Daily Mass, children’s shows, live coverage of Papal & Church events, and live shows. EWTN is dedicated to teaching the truth as defined by the Magisterium of the Roman Catholic Church. Ch. #370"},
        {"id": "FIRST","sizzleVideoId": "483250713", "logoName": "FirstMedia", "short_name": "First Media", "name": "First Media", "tier": 3, "prizes": ['First Media Pack', 'First Media Pack', 'First Media Pack', 'First Media Pack', 'First Media Pack'],  "description": "First Media’s lifestyle-focused brands inspire women every day to reimagine the ordinary to extraordinary. The multi-platform portfolio of entertainment and life hacking brands including baby first TV, So Yummy, Blossom and Blusher reach more than 160 million people on cable, digital, and out-of-home each month. baby first is a TV channel in 60M TV Homes in the US focused on educational content for children 0-3, and their parents. 98% of viewership is co-viewing, so the channel also provides parenting tips and fun projects for the whole family."},
        {"id": "FOX", "sizzleVideoId": "483269739", "logoName": "Fox", "short_name": "FOX Corporation", "name": "FOX Corporation", "tier": 3, "prizes": ['Masked Singer Karaoke Machine', 'Masked Singer Karaoke Machine', 'FS1 Coleman NX Portable Grill', 'Fox Ray Ban Sunglasses', 'Fox Ray Ban Wayfarer Sunglasses', ],  "description": "Fox Corporation produces and distributes compelling news, sports and entertainment content through some of the world’s leading and most valued brands, including: FOX News, FOX Sports And FOX Entertainment. The breadth and depth of our footprint allows us to produce and distribute content that engages and informs audiences, develops deeper consumer relationships and creates more compelling product offerings."},
        {"id": "GSN","sizzleVideoId": "483246773", "logoName": "GameshowNetwork", "short_name": "GAME SHOW NETWORK", "name": "Game Show Network", "tier": 1, "prizes": ['Sony Noise Cancelling Headphones', 'Sony Noise Cancelling Headphones', 'Sony Noise Cancelling Headphones', 'Sony Noise Cancelling Headphones', 'Sony Noise Cancelling Headphones'],  "description": "Game Show Network is the home of your favorite classic and engaging original game show programming. Dedicated exclusively to the game show genre, Game Show Network is a destination channel providing viewers with a daily dose of feel-good and family-friendly programming with winning personalities, play from your armchair entertainment, and wall-to-wall fun."},
        {"id": "HBO", "mainStageVideoId": "483271264", "sizzleVideoId": "483270274", "logoName": "HBOMAX", "short_name": "HBO Max (WarnerMedia)", "prizes": ['HBO Max Apple TV'], "name": "HBO Max (Warner Media)", "tier": 3,  "description": "HBO Max is WarnerMedia’s streaming offering, with 10,000 hours of curated premium content. HBO Max is home to best in class quality entertainment, featuring the greatest array of storytelling for all audiences with the iconic brands of HBO, Warner Bros., DC, Cartoon Network, Adult Swim, Turner Classic Movies and much more."},
        {"id": "INSP", "sizzleVideoId": "483269898", "logoName": "Insp", "short_name": "INSP", "name": "INSP", "tier": 3, "prizes": ['$100 Visa gift card', '$100 Visa gift card', '$100 Visa gift card', '$100 Visa gift card', '$100 Visa gift card'],  "description": "INSP provides a trusted viewing experience with a lineup of exclusive and original series, timeless westerns, action-filled dramas and films focused on adventure and heroic characters. The pioneers who bravely settled the West. Lawmen willing to defend freedom and stand for principle, no matter what the odds. Cowboys, taking risks, living on the edge. Men and women in all walks of life trying to do the right thing. All of them, in their own ways, heroes."},
        {"id": "ION","sizzleVideoId": "483259996", "logoName": "IonMediaNetworks", "short_name": "ION Television", "name": "ION Media Networks ", "tier": 3, "prizes": ['Amazon Echo Show', 'Amazon Echo Show', 'Home charging pad, dual alarm clock, speakerphone, charging pad', 'Ultimate Ears Wonderboon Speaker', 'Beats Solo3 Wireless Headphones', ],  "description": "ION Television is the #1 general entertainment network featuring a positively entertaining binge-friendly lineup. The network airs proven award winning programs, quality original series, and popular holiday movies. ION’s daily marathons boast 7 of the top 10 most watched dramas, such as Law & Order SVU, Blue Bloods, Criminal Minds, and more. This holiday season, ION is showcasing over 125 hours of original holiday movies, from fan favorites to new premieres. ION is the #2 most watch holiday network -truly making ION your home for the holidays. \n\nION Television is the flagship network of the ION Media family, which also includes ION Plus and QUBO."},
        {"id": "MATV", "sizzleVideoId": "483257921","logoName": "MavTV", "short_name": "MAVTV Motorsports Network", "name": "MATV Motorsports Network", "tier": 1, "prizes": ['Lucas Oil Tool Bag filled with items', 'Lucas Oil Tool Bag filled with items'],  "description": "MAVTV Motorsports Network Offers an unparalleled line-up of exclusive motorsports events.  MAVTV LIVE racing coverage includes NASCAR ARCA Racing Series, AMA Pro Motocross, and the prestigious Chili Bowl Nationals.  MAVTV covers a multitude of grassroots racing from across the country including: Supercross, Drifting, Late Model Dirt Series, Pro Pulling League, American Sprint Car Series, Off Road Truck Racing Series, SRL Modifieds, Drag Boats and so much more."},
        {"id": "MEGATV","sizzleVideoId": "483248690", "logoName": "MegaTV", "short_name": "MegaTV", "name": "MegaTV-Spanish Broadcasting System", "tier": 3, "prizes": ['Samsonite Executive Computer Bags', 'Samsonite Executive Computer Bags', 'Samsonite Executive Computer Bags', 'Samsonite Executive Computer Bags', 'Samsonite Executive Computer Bags'],  "description": "MegaTV features original programming aimed toward the U.S. Hispanic Audience seeking an Alternative to Mexican Oriented Cable Networks. Unlike Other Hispanic TV Networks, MegaTV primarily Focuses on LIVE Original Programs and Engaging Content, produced daily from our Production Centers in Miami and Puerto Rico. Additional Co-Production Partnerships with Central and South America round up our unique content strategy."},
        {"id": "MGM", "sizzleVideoId": "483267260", "logoName": "MGM", "short_name": "MFGM HD", "name": "MGM Studios Inc", "tier": 1, "prizes": ['MGM Studios Inc. Packs', 'MGM Studios Inc. Packs', 'MGM Studios Inc. Packs', 'MGM Studios Inc. Packs', 'MGM Studios Inc. Packs', 'James Bond Collection, 24 Bond Films', 'MGM HD Backpacks', 'MGM HD Baseball Caps', ],  "description": "A movie network showcasing the legendary MGM film library. From blockbusters to hidden gems DIRECTV customers can experience the full scope of this incredible body of work. MGM HD features film franchises like James Bond, The Pink Panther and Rocky. We also provide monthly actor retrospectives. And we have four movie genre programming blocks: Film Noir Features(Noir), Frontier Features(Westerns), Cult Classics(B movies) and coming soon VHS TV(80’s movies). Plus MGM HD airs studio only behind-the-scenes short-form content.  MGM HD is available on Movies Extra Pack Channel 567."},
        {"id": "MLB", "sizzleVideoId": "486954276","logoName": "MLB", "short_name": "MLB Network", "name": "MLB Network", "tier": 1, "prizes": ['Autographed baseball', 'Autographed baseball', 'Autographed baseball', 'Autographed baseball', 'Autographed baseball'], "description": "MLB Network is the ultimate television destination for baseball fans, featuring the multiple Emmy Award-winning MLB Tonight, live regular season and Postseason game telecasts, original programming, highlights, insights and analysis."},
        {"id": "MLT", "sizzleVideoId": "483269456", "logoName": "Multimedios", "short_name": "Multimedios Television", "name": "Multimedios Television", "tier": 3, "prizes": ['$100 Amazon gift card', '$100 Amazon gift card', '$100 Amazon gift card', '$100 Amazon gift card', '$100 Amazon gift card'],  "description": "Multimedios  Television presents  LIVE  fun-filled  Spanish-language  entertainment  for  the  entire  family targeting Mexican and Latino audiences across the U.S. direct from Mexico, with over 140 hours of original programming  per  week  including  children's,  millennials,  women,  music, sports,  news  and  some  of  the most tweeted reality shows on Mexican TV!"},
        {"id": "NBC", "sizzleVideoId": "483264580","logoName": "NBCuniversal", "short_name": "NBCUniversal", "name": "NBC Universal", "tier": 1, "prizes": ['Bose Soundlink Micro Bluetooth Speaker', 'Bose Soundlink Micro Bluetooth Speaker', 'Bose Soundlink Micro Bluetooth Speaker', 'Bose Soundlink Micro Bluetooth Speaker', 'Bose Soundlink Micro Bluetooth Speaker'],  "description": "We are one of the world’s leading media and entertainment companies in the development, production, and marketing of entertainment, news and information to a global audience. NBCUniversal owns and operates a valuable portfolio of news and entertainment television networks, a premier motion picture company, significant television production operations, a leading television stations group, world-renowned theme parks, and a suite of leading Internet-based businesses. NBCUniversal is a subsidiary of Comcast Corporation."},
        {"id": "NMAX", "sizzleVideoId": "","logoName": "NewsMax", "short_name": "Newsmax", "name": "Newsmax", "tier": 1, "prizes": ['Newsmax Pack', 'Newsmax Pack', 'Newsmax Pack', 'Newsmax Pack', 'Newsmax Pack', 'Newsmax Cap', 'Newsmax Travel Mug'],  "description": "Newsmax TV is a 24/7 leading cable news channel with reporting from Washington, New York, Hollywood and across the U.S., focusing on politics, health, money, documentaries and more."},
        {"id": "VIA", "mainStageVideoId": "483245909", "sizzleVideoId": "483244695", "logoName": "ViacomCBS", "short_name": "Nickelodeon", "name": "Nickelodeon", "tier": 3, "prizes": [], "description": "Nickelodeon, now in its 40th year, is the number-one entertainment brand for kids. It has built a diverse, global business by putting kids first in everything it does. Nickelodeon's newest show The Astronauts tells the story of 5 kids who are accidentally blasted into space, and will have to become a team in order to survive. How far will they go to get back home? Tune-in Friday's at 7/6c"},
        {"id": "OUT","sizzleVideoId": "483257378", "logoName": "OutdoorChannel", "short_name": "Outdoor Channel", "name": "Outdoor Channel", "tier": 3, "prizes": ['Yeti Roadies', 'Yeti Roadies', 'Solo Stove', 'Solo Stove', 'RTIC Soft Sided Cooler'], "description": "Come to Outdoor Channel for epic adventures, exhilarating action in the field and on the water, charismatic personalities and the best in outdoor-centric cinema. Honoring a lifestyle that is celebrated by millions of Americans, Sportsman Channel presents highly-entertaining hunting, fishing and shooting programming. WFN is the only channel dedicated to life on the water and the wide variety of related travel and cultural experiences it offers."},
        {"id": "OVA",  "sizzleVideoId": "483267010", "logoName": "Ovation", "short_name": "Ovation TV", "name": "Ovation TV", "tier": 1, "prizes": ['Ovation Bluetooth Air Pods', 'Ovation Bluetooth Air Pods', 'Ovation Bluetooth Air Pods', 'Ovation Bluetooth Air Pods', 'Ovation Bluetooth Air Pods', 'Ovation Bluetooth Air Pods', 'Ovation Bluetooth Air Pods', 'Ovation Bluetooth Air Pods', 'Ovation Bluetooth Air Pods', 'Ovation Bluetooth Air Pods', ],  "description": "Ovation TV is an independent television and digital media company dedicated to celebrating art in all its forms. From critically-acclaimed international dramas, arts-related documentaries and culturally-inspired series (Inside the Actors Studio), we deliver engaging, premium content to our passionate audience. We continue to expand our mission to support world-class creativity by advancing the next generation of artists through Stand For The Arts, our national arts advocacy initiative to raise awareness, protect access and encourage action on behalf of the arts."},
        {"id": "PAS","sizzleVideoId": "485586672", "logoName": "Pasiones", "short_name": "Pasiones (Hemisphere Media Group)", "name": "Pasiones (Hemisphere Media Group)", "tier": 1, "prizes": [], "description": "The leading network for telenovelas and dramatic series in Spanish, featuring a mix of the most recent hits and everlasting classics from Turkey, Brazil, Mexico, Colombia, Chile, Argentina, the U.S., and India among others."},
        {"id": "QVC","sizzleVideoId": "483257323", "logoName": "QVC", "short_name": "QVC/HSN", "name": "QVC/HSN", "tier": 3, "prizes": ['Bose SoundLink Micro Bluetooth Speaker', 'Bose SoundLink Micro Bluetooth Speaker', 'Bose SoundLink Micro Bluetooth Speaker', 'Bose SoundLink Micro Bluetooth Speaker', 'Bose SoundLink Micro Bluetooth Speaker'],  "description": "Engaging. Surprising. Authentic. And live 21 hours a day, every day! Tune in to QVC® to watch and shop original shows and special events. And get your first look at our daily deals when the Today’s Special Value® kicks off at Midnight ET and a new QVC2 Big Deal® launches every day. Watch and shop QVC anytime—at home or on the go."},
        {"id": "RTV", "sizzleVideoId": "483253584","logoName": "RevoltTV", "short_name": "REVOLT TV", "name": "REVOLT TV", "tier": 1, "prizes": ['REVOLT TV Exclusive Merchandise', 'REVOLT TV Exclusive Merchandise', 'REVOLT TV Exclusive Merchandise', 'REVOLT TV Exclusive Merchandise', 'REVOLT TV Exclusive Merchandise'], "description": "REVOLT is unapologetically Hip Hop, leading and living Hip Hop culture. REVOLT is the voice of the culture across platforms, engaging Millennial and Gen Z audiences, on REVOLT.tv, across social,  TV  and  live  events,  through  original  and  live  content.  Attracting  over  50  million  young people, REVOLT is accessible 24/7 on digital, TV and on demand. Founded by Sean “Diddy” Combs,  REVOLT  launched  in  broadcast  in  October  2013  and  is  available  on  AT&T  DirecTV platforms,  Charter  Spectrum,  Comcast  Xfinity,  Verizon  FiOS,  CenturyLink,  Altice/Suddenlink, Frontier  Communications,  Comporium  and  Cincinnati  Bell,  Atlantic  Broadband,  Mediacom, Hotwire, as well as OTT platforms AT&TV Now, Sling TV, Fubo TV and Philo TV."},
        {"id": "SHORTZ","sizzleVideoId": "483266541", "logoName": "ShortsTV", "short_name": "ShortsTV", "name": "ShortsTV", "tier": 3, "prizes": ['GoPro - Hero 7, Black 4K Waterproof Active Camera', 'GoPro - Hero 7, Black 4K Waterproof Active Camera', 'GoPro - Hero 7, Black 4K Waterproof Active Camera', 'GoPro - Hero 7, Black 4K Waterproof Active Camera', 'GoPro - Hero 7, Black 4K Waterproof Active Camera'],  "description": "ShortsTV is the first and only 24/7 linear and OTT channel dedicated to short form entertainment and the exclusive presenter of the Academy Award®-nominated short film theatrical releases. ShortsTV remains steadfast in it’s advocacy for short form filmmakers and spotlighting diverse perspectives, giving a voice and platform for emerging talent.  With over 13,000 titles, ShortsTV has the largest catalogue of quality short form entertainment, including  award-winning and star-studded live action, animated and documentary shorts from filmmakers around the globe.  Distributed globally, the channel is available in 70 million homes across the US, Latin America, Europe and Asia."},
        {"id": "SHOWTIME", "mainStageVideoId": "484951828", "sizzleVideoId": "484951763", "logoName": "Showtime", "short_name": "SHOWTIME®", "name": "Showtime Networks", "tier": 3, "prizes": [], "description": "SHOWTIME® is home to one of the most successful programming lineups on TV –featuring critically acclaimed original series, provocative documentaries, hit films, comedy and music specials and hard-hitting sports. The network is home to rich, bold and inventive storytelling in series like HOMELAND, SHAMELESS, BILLIONS, THE CHI, THE L WORD®: GENERATION Q and WORK IN PROGRESS, along with limited series THE LOUDEST VOICE and THE GOOD LORD BIRD. Last year, SHOWTIME premiered its first ever late-night talk show, DESUS & MERO, and Emmy®-winning news series VICE."},
        {"id": "STARZ", "mainStageVideoId": "483249914", "sizzleVideoId": "483250625","logoName": "Starz", "short_name": "STARZ", "name": "Starz", "tier": 3, "prizes": ['Google Home - Smart Speaker', 'Google Home - Smart Speaker'], "description": "Starz, a Lionsgate company (NYSE: LGF.A, LGF.B), is a leading global media and entertainment company that produces and distributes premium streaming content to worldwide audiences across subscription television platforms. Starz is home to the flagship domestic STARZ® brand, STARZ ENCORE, 17 premium pay TV channels and the associated on-demand and online services, including the highly rated STARZ app. Starz offers subscribers more than 7,500 distinct premium television episodes and feature films, including STARZ Original series, first-run movies and other popular programming."},
        {"id": "TFC", "sizzleVideoId": "487952138", "logoName": "TFC", "short_name": "TFC", "name": "TFC", "tier": 1, "prizes": ['$50 Amazon gift card', '$50 Amazon gift card', '$50 Amazon gift card', '$50 Amazon gift card'],  "description": "TFC is more than just TV. TFC aims to bring Filipinos together by bringing out the best in Filipino culture and reflecting every Filipino’s sentiment. Through the years, TFC has been steadfast in its commitment to deliver inspiring stories, entertaining programs, and relevant news from the homeland. It has helped Filipino immigrants to properly adapt to their new environment by connecting them to the Filipino experience. It has also been a purveyor of Filipino culture and values to younger generations, making them understand and appreciate better the Filipino identity and pride."},
        {"id": "TNT", "sizzleVideoId": "483257179","logoName": "TNT", "short_name": "TNT (WarnerMedia)", "name": "TNT (Warner Media)", "tier": 3, "prizes": ['HBO Max Bose Headphones', 'HBO Max Herschel Backpack', 'HBO Max Yeti Rambler Mug'], "description": "TNT’s top-rated, post-apocalyptic sci-fi thriller, Snowpiercer, returns January 25th and will add cast members Sean Bean (“Game of Thrones”) as the antagonist Mr. Wilford and Rowan Blanchard (“Girl Meets World”), who will portray Jennifer Connelly’s presumed-dead daughter. Season 2 will follow the revolution’s survivors, led by Layton (Daveed Diggs), as they attempt to maintain peace among the recently merged classes. But an emerging power struggle threatens the already-fragile ecosystem. Watch season 2 on TNT starting January 25th & catch up on season 1 on HBO Max!"},
        {"id": "UNI","sizzleVideoId": "", "logoName": "Univision", "short_name": "Univision Communications", "name": "Univision Communications Inc.", "tier": 3, "prizes": ['JBL Speakers', 'JBL Speakers', 'JBL Speakers', 'JBL Speakers'],  "description": "As  the  leading  Hispanic media  company  in  the  U.S.,  Univision  Communications  Inc.  entertains, informs  and  empowers  U.S.  Hispanics  with  news,  sports  and  entertainment  content  across broadcast and cable television, audio and digital platforms. The company’s top-rated  media portfolio  includes  the  Univision  and  UniMás  broadcast  networks,  as  well  as  cable  networks Galavisión  and  TUDN,  the  No.  1  Spanish-language  sports  network  in  the  country.  Locally, Univision owns or operates 65 television stations in major U.S.Hispanic markets and Puerto Rico. Additionally, Uforia, the Home of Latin Music, encompasses 58 owned or operated radio stations, a live event series and a robust digital audio footprint. The company’s prominent digital assets include Univision.com, streaming service Univision Now, the largest Hispanic influencer network and several top-rated apps. For more information, visit corporate.univision.com."},
        {"id": "UP","sizzleVideoId": "483251378", "logoName": "Up Entertainment", "short_name": "UP Entertainment", "name": "UP Entertainment", "tier": 1, "prizes": ['UPTV / Aspir TV Lifestyle Prize Pack', 'UPTV / Aspir TV Lifestyle Prize Pack', 'UPTV / Aspir TV Lifestyle Prize Pack', 'UPTV / Aspir TV Lifestyle Prize Pack', 'UPTV / Aspir TV Lifestyle Prize Pack'], "description": "UP Entertainment, home to UPtv, UP Faith & Family and AspireTV, is the destination for positive and authentic storytelling that is relevant to each network’s audience. UPtv, the trusted network for uplifting entertainment, offers exclusive premiere movies, uplifting theatricals and beloved series. UP Faith & Family, America’s favorite streaming service for families, gives instant access to the best in family and faith-friendly entertainment, and AspireTV is the leading network for Black and urban lifestyle programming."},
        {"id": "VME","sizzleVideoId": "483260183", "logoName": "Vme", "short_name": "Vme Media", "name": "Vme Media Inc", "tier": 1, "prizes": ['Vme Media Wireless Charger', 'Vme Media Wireless Charger', 'Vme Media Wireless Charger', 'Vme Media Wireless Charger', 'Vme Media Wireless Charger'],  "description": "Vme  TV  is a  premiere  national  Spanish  language  television  network  that  provides  a  quality alternative   to   Latino   families   by   selecting   programming   that   is   engaging,   empowering, educational  and  entertaining.  Vme TV is  available  in millions  of households across the United States, distributed  via  DIRECTV, DISH  Network, AT&T  U-verse and  AT&T  TV  Now. The  24-hour digital broadcast service is dedicated to entertaining, educating and inspiring families in Spanish with a contemporary mix of original productions, exclusive premieres, acquisitions, and popular television programs specially adapted for Hispanics. To find Vme TV or to learn more about the network, visit www.vmetv.com and follow us on social media via www.facebook.com/vmetv or www.twitter.com/vmetv."},
        {"id": "WMG","sizzleVideoId": "", "logoName": "", "short_name": "WarnerMedia", "name": "WarnerMedia", "tier": 1, "prizes": [],  "description": ""},
        {"id": "HMG","sizzleVideoId": "", "logoName": "", "short_name": "Hemisphere", "name": "Hemisphere", "tier": 1, "prizes": [],  "description": ""}
    ],
    programmers: [
        { sponsorId: 'AE', email: 'rena.friedlander@aenetworks.com' },
        { sponsorId: 'AMC', email: 'amber.callahan@amcnetworks.com' },
        { sponsorId: 'AMC', email: 'Jonathan.gonzalez@amcnetworks.com' },
        { sponsorId: 'AMC', email: 'barbara.kalosieh@amcnetworks.com' },
        { sponsorId: 'AMC', email: 'Marissa.karo@amcnetworks.com' },
        { sponsorId: 'AMC', email: 'Lara.maggin@amcnetworks.com' },
        { sponsorId: 'AMC', email: 'beth.parks@amcnetworks.com' },
        { sponsorId: 'AMC', email: 'lisa.penha@amcnetworks.com' },
        { sponsorId: 'AMC', email: 'josh.reader@amcnetworks.com' },
        { sponsorId: 'AMC', email: 'alden.schell@amcnetworks.com' },
        { sponsorId: 'AMC', email: 'jonathan.sichel@amcnetworks.com' },
        { sponsorId: 'AMC', email: 'joseph.stefan@amcnetworks.com' },
        { sponsorId: 'AXS', email: 'dnadel@axs.tv' },
        { sponsorId: 'CARA', email: 'abernalr@caracoltv.com.co' },
        { sponsorId: 'CARA', email: 'icrivero@caracoltv.com.co' },
        { sponsorId: 'OLYM', email: 'clopez@olympusat.com' },
        { sponsorId: 'OLYM', email: 'ralph@olympusat.com' },
        { sponsorId: 'CLEO', email: 'kgunter-robinson@tvone.tv' },
        { sponsorId: 'CLEO', email: 'rpeters@tvone.tv' },
        { sponsorId: 'CMF', email: 'LisaBarroso@crownmedia.com' },
        { sponsorId: 'CMF', email: 'christinedelestowicz@crownmedia.com' },
        { sponsorId: 'CMF', email: 'hollyhenderson@crownmedia.com' },
        { sponsorId: 'CMF', email: 'erinmcilvain@crownmedia.com' },
        { sponsorId: 'CMF', email: 'tracyvanslyke@crownmedia.com' },
        { sponsorId: 'DI', email: 'michelle_castro@discovery.com' },
        { sponsorId: 'DI', email: 'kelly_kane@discovery.com' },
        { sponsorId: 'DI', email: 'eric_phillips@discovery.com' },
        { sponsorId: 'DI', email: 'colin_templeton@discovery.com' },
        { sponsorId: 'DI', email: 'arti_umesh@discovery.com' },
        { sponsorId: 'ESW', email: 'janice@es.tv' },
        { sponsorId: 'ESW', email: 'bruce.gibson@weathergroup.com' },
        { sponsorId: 'ESW', email: 'droberts@es.tv' },
        { sponsorId: 'EPIX', email: 'mdiana@epix.com' },
        { sponsorId: 'EPIX', email: 'stravis@epix.com' },
        { sponsorId: 'ESPN', email: 'Joseph.A.Hanna@disney.espn.com' },
        { sponsorId: 'ESPN', email: 'Natalie.Marcuzzo@disney.com' },
        { sponsorId: 'ESPN', email: 'Idia.Ogala@disney.com' },
        { sponsorId: 'EWTN', email: 'twenzel@ewtn.com' },
        { sponsorId: 'FIRST', email: 'Srechter@bf-tv.com' },
        { sponsorId: 'FOX', email: 'jen.edisis@fox.com' },
        { sponsorId: 'FOX', email: 'christy.goulas@fox.com' },
        { sponsorId: 'FOX', email: 'liya.hershkowitz@fox.com' },
        { sponsorId: 'GSN', email: 'anavarro@gameshownetwork.com' },
        { sponsorId: 'HMG', email: 'agarrido@hemispheretv.com' },
        { sponsorId: 'HMG', email: 'sgomez-dieb@hemispheretv.com' },
        { sponsorId: 'HMG', email: 'lmontalvo@hemispheretv.com' },
        { sponsorId: 'INSP', email: 'jarthur@insp.com' },
        { sponsorId: 'INSP', email: 'mjones@insp.com' },
        { sponsorId: 'INSP', email: 'mkang@insp.com' },
        { sponsorId: 'INSP', email: 'Komeara@insp.com' },
        { sponsorId: 'ION', email: 'liatabada@ionmedia.com' },
        { sponsorId: 'ION', email: 'CaraConte@ionmedia.com' },
        { sponsorId: 'ION', email: 'RonSaykin@ionmedia.com' },
        { sponsorId: 'MATV', email: 'jduff@mavtv.com' },
        { sponsorId: 'MATV', email: 'eniemi@mavtv.com' },
        { sponsorId: 'MATV', email: 'dteitscheid@mavtv.com' },
        { sponsorId: 'MEGATV', email: 'aaleman@sbscorporate.com' },
        { sponsorId: 'MEGATV', email: 'fframil@mega.tv' },
        { sponsorId: 'MEGATV', email: 'mgonzalez@mega.tv' },
        { sponsorId: 'MEGATV', email: 'pjaspar@sbscorporate.com' },
        { sponsorId: 'MEGATV', email: 'josep@mega.tv' },
        { sponsorId: 'MEGATV', email: 'arodriguez@sbsmiami.com' },
        { sponsorId: 'MEGATV', email: 'dross@mega.tv' },
        { sponsorId: 'MGM', email: 'tully@hothouse-media.com' },
        { sponsorId: 'MGM', email: 'DChalfant@mgm.com' },
        { sponsorId: 'MGM', email: 'claire.deboer@mlb.com' },
        { sponsorId: 'MLB', email: 'Brent.fisher@mlb.com' },
        { sponsorId: 'MLB', email: 'joe.ribando@mlb.com' },
        { sponsorId: 'MLT', email: 'alberto.dominguez@multimedios.com' },
        { sponsorId: 'MLT', email: 'magda@unoentertainment.com' },
        { sponsorId: 'NBC', email: 'fred.haug@nbcuni.com' },
        { sponsorId: 'NBC', email: 'Brooke.schasteen-smith@nbcuni.com' },
        { sponsorId: 'NMAX', email: 'Abiggers@newsmax.com' },
        { sponsorId: 'NMAX', email: 'cmras@aol.com' },
        { sponsorId: 'VIA', email: 'Andrew.Borak@viacom.com' },
        { sponsorId: 'VIA', email: 'mike.correia@cbs.com' },
        { sponsorId: 'VIA', email: 'aaliyah.harris@viacom.com' },
        { sponsorId: 'VIA', email: 'Rebecca.Mohabir@viacom.com' },
        { sponsorId: 'VIA', email: 'Rebecca.Mohabir@viacom.com' },
        { sponsorId: 'VIA', email: 'ryan.murray@viacom.com' },
        { sponsorId: 'VIA', email: 'charvi.radia@viacom.com' },
        { sponsorId: 'OUT', email: 'nholm@outdoorchannel.com' },
        { sponsorId: 'OVA', email: 'sdoten@ovationtv.com' },
        { sponsorId: 'OVA', email: 'jkanter@ovationtv.com' },
        { sponsorId: 'OVA', email: 'jmalkin@ovationtv.com' },
        { sponsorId: 'QVC', email: 'David.M.Apostolico@qvc.com' },
        { sponsorId: 'QVC', email: 'michael.smith@qvc.com' },
        { sponsorId: 'QVC', email: 'jodi.teitelman@qvc.com' },
        { sponsorId: 'RTV', email: 'jbrown@revolt.tv' },
        { sponsorId: 'RTV', email: 'dduff@revolt.tv' },
        { sponsorId: 'RTV', email: 'abturner@revolt.tv' },
        { sponsorId: 'SHORTZ', email: 'j.allen@shorts.tv' },
        { sponsorId: 'SHORTZ', email: 'c.cassese@shorts.tv' },
        { sponsorId: 'SHORTZ', email: 'c.pilcher@shorts.tv' },
        { sponsorId: 'SHORTZ', email: 'e.rodriguez@shorts.tv' },
        { sponsorId: 'SHOWTIME', email: 'suzanne.altshuler@showtime.net' },
        { sponsorId: 'SHOWTIME', email: 'brett.brown@showtime.net' },
        { sponsorId: 'SHOWTIME', email: 'wendy.castillo@showtime.net' },
        { sponsorId: 'SHOWTIME', email: 'christina.alexander@showtime.net' },
        { sponsorId: 'SHOWTIME', email: 'Allison.Cowperthwaite@showtime.net' },
        { sponsorId: 'SHOWTIME', email: 'melissa.fernandez@showtime.net' },
        { sponsorId: 'SHOWTIME', email: 'ivonne.maldonado@showtime.net' },
        { sponsorId: 'STARZ', email: 'danielle.garcia@starz.com' },
        { sponsorId: 'STARZ', email: 'evan.grossman@starz.com' },
        { sponsorId: 'STARZ', email: 'sil.mangalee@starz.com' },
        { sponsorId: 'TFC', email: 'pam_castillo@abs-cbn.com' },
        { sponsorId: 'TFC', email: 'rosary_escano@abs-cbn.com' },
        { sponsorId: 'TFC', email: 'maile_llaneza@abs-cbn.com' },
        { sponsorId: 'UNI', email: 'apetzold@univision.net' },
        { sponsorId: 'UP', email: 'hsymmes@uptv.com' },
        { sponsorId: 'VME', email: 'ecedeno@vmetv.com' },
        { sponsorId: 'VME', email: 'mfernandez@vmetv.com' },
        { sponsorId: 'VME', email: 'lmedina@vmetv.com' },
        { sponsorId: 'VME', email: 'dvolgelmann@vmetv.com' },
        { sponsorId: 'WMG', email: 'nicholas.alanis@hbo.com' },
        { sponsorId: 'WMG', email: 'Sarah.Bills@hbo.com' },
        { sponsorId: 'WMG', email: 'Jaclyn.Caracciolo@hbo.com' },
        { sponsorId: 'WMG', email: 'jonathan.cherry@hbo.com' },
        { sponsorId: 'WMG', email: 'diana.dai@hbo.com' },
        { sponsorId: 'WMG', email: 'gina.desantis@hbo.com' },
        { sponsorId: 'WMG', email: 'Natalia.Guzman@hbo.com' },
        { sponsorId: 'WMG', email: 'alexis.hambrick@warnermedia.com' },
        { sponsorId: 'WMG', email: 'ramonica.harton@warnermedia.com' },
        { sponsorId: 'WMG', email: 'Ann.Hataway@hbo.com' },
        { sponsorId: 'WMG', email: 'kerrie.head@warnermedia.com' },
        { sponsorId: 'WMG', email: 'Mackenzie.Hummel@hbo.com' },
        { sponsorId: 'WMG', email: 'Kslau0013@gmail.com' },
        { sponsorId: 'WMG', email: 'amy.leasca@hbo.com' },
        { sponsorId: 'WMG', email: 'Jzmartin1@gmail.com' },
        { sponsorId: 'WMG', email: 'lauren.mccabe@turner.com' },
        { sponsorId: 'WMG', email: 'Melissa.ann.kleinberg@gmail.com' },
        { sponsorId: 'WMG', email: 'beth.moneyhan@warnermedia.com' },
        { sponsorId: 'WMG', email: 'leah.penkava@hbo.com' },
        { sponsorId: 'WMG', email: 'kate.pluta@hbo.com' },
        { sponsorId: 'WMG', email: 'ra7852@att.com' },
        { sponsorId: 'WMG', email: 'Christina.Reynolds@hbo.com' },
        { sponsorId: 'WMG', email: 'matthew.schmidt@hbo.com' },
        { sponsorId: 'WMG', email: 'christina.schoellen@warnermedia.com' },
        { sponsorId: 'WMG', email: 'robyn.watson@turner.com' },
        { sponsorId: 'WMG', email: 'fwebb@doublealabs.com' },
        { sponsorId: 'WMG', email: 'dfitzpatrick@doublealabs.com' },
        { sponsorId: 'WMG', email: 'tjrotell@doublealabs.com' },
        { sponsorId: 'WMG', email: 'ddickey@doublealabs.com' },
        { sponsorId: 'WMG', email: 'ahna@doublealabs.com' },
    ]
}