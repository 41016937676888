<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

        <!-- Page sections -->
        <AccountHero :sponsorId="sponsorId" />
        <VendorStats :sponsorId="sponsorId" />
    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import AccountHero from './../partials/AccountHero.vue'
import VendorStats from './../partials/VendorStats.vue'
import Footer from './../partials/Footer.vue'
import { programmerCollection } from '../firebase.js'

export default {
  name: 'Dashboard',
  components: {
    Header,
    AccountHero,
    VendorStats,
    Footer,
  },
  inject: ['currentUser'],
  data() {
    return { sponsorId: '' }
  },
  methods: {
    
  },
  mounted() {
    programmerCollection.where('emails', 'array-contains', this.currentUser.email).get()
      .then((programmerSnap) => {
          if (!programmerSnap.empty) {
              this.sponsorId = programmerSnap.docs[0].id
              // console.dir(programmerSnap)
              // programmerCollection.doc(sponsorId).get()
              //     .then(snapshot => {
              //         if (!snapshot.empty) {
              //             this.interactionCount = snapshot.data().interactions
              //         }
              //     })
              //     .catch(error => {
              //         console.error(`Failed to query total interactions for sponsor ${sponsorId}. Details: ${error.message}`)
              //     })
          }
      })
      .catch((error) => {
          console.error(`Failed to find programmer for email ${this.currentUser.email}. Details: ${error.message}`)
      })    
  },

}
</script>
