<template>
  <section class="relative">

    <div class="relative max-w-6xl mx-auto px-4 mb-12 sm:px-6 border-b border-gray-200">
      <div class="md:py-20">
        <div class="transform rotate-90 -translate-y-10 flex items-center justify-center">
          <svg width="3px" height="81px" viewbox="0 0 3 81" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0L2 0L2 80L0 80L0 0Z" transform="translate(0.5 0.5)" id="Rectangle" fill="#009FDB" fill-rule="evenodd" stroke="none" />
          </svg>
        </div>

        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
          <h2 class="h2 mb-4" data-aos="zoom-y-out">Thank you to our top sponsors</h2>
        </div>

        <!-- Items -->
        <div class="logo-grid max-w-sm mx-auto grid gap-y-16 gap-x-16 md:grid-cols-2 lg:grid-cols-4 items-center md:max-w-2xl lg:max-w-none lg:px-8" data-aos="zoom-y-out" data-aos-delay="150">

            <div class="relative flex flex-col place-items-center ">
            <img class="h-1/2" :src="require('@/images/SquareLogo/AMC.png')"  />
            </div>

            <div class="relative flex flex-col place-items-center ">
            <img class="h-1/2" :src="require('@/images/SquareLogo/CrownMedia.png')"  />
            </div>
            <div class="relative flex flex-col place-items-center ">
            <img class="h-1/2" :src="require('@/images/SquareLogo/Discovery.png')"  />
            </div>

            <div class="relative flex flex-col place-items-center ">
            <img class="h-1/2" :src="require('@/images/SquareLogo/ESPN.png')"  />
            </div>

            <div class="relative flex flex-col place-items-center ">
            <img class="h-1/2" :src="require('@/images/SquareLogo/HBOMAX.png')"  />
            </div>

            <div class="relative flex flex-col place-items-center ">
            <img class="h-1/2" :src="require('@/images/SquareLogo/ViacomCBS.png')"  />
            </div>

            <div class="relative flex flex-col place-items-center ">
                <img class="h-1/2" :src="require('@/images/SquareLogo/Showtime.png')"  />
            </div>
            <div class="relative flex flex-col place-items-center ">
            <img class="h-1/2" :src="require('@/images/SquareLogo/Starz.png')"  />
            </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'LogoGrid',
}
</script>