<template>
  <div class="flex flex-col min-h-screen font-game game select-none overscroll-none bg-game-dark" >  
    <!-- overflow-hidden -->

    <!-- Page content -->
    <main class="flex-grow relative" style="max-width: 2700px;">
      <Board ref="board" :avatarIndex="avatarIndex" />

      <div class="fixed top-0 w-full z-20">
        <img class="mx-auto" style="width: 600px; margin-top: -30px;" :src="require('@/images/game/ui/banner.png')" />
      </div>

      <!-- Page sections -->
      <div class="flex flex-col fixed top-0 left-0 mt-4 ml-4 z-20">
        <AchievementList ref="listAchievement" 
          :progressMiniGames="miniGamesRevealed" 
          :progressPower="spacesRevealed" 
          :progressGlitch="glitchesRevealed"
          :progressMainStage="mainStageClicked"
          :progressHacker="zonesCompleted"
          :achievementBinary="achievementBinary"
          :achievementCracker="achievementCracker"/>
      </div>
      
      <div class="flex flex-col justify-items-center items-end fixed top-0 right-0 my-4 mr-4 z-20">
        <Photobooth />
        <SponsorList />
      </div>

      <ExitButton class="fixed right-0 bottom-0 mr-4 mb-4" />
      <MuteButton ref="mute-button" class="fixed left-0 bottom-0 ml-4 mb-4" />

      <div class="fixed bottom-0 left-1/2 -ml-32 mb-8" >
        <div class="px-6 bg-game-dark border-2 border-game-blue-light rounded-full text-game-blue text-center text-md">
          <div class="my-auto py-2">Total Charity Donations: ${{ charityTotal }}</div>
        </div>
      </div>
      
      <AchievementModal ref="acv-glitch" :showModal="showAchievementGlitch" :title="'Glitch in the system'" :leftSponsor="'ION'" :rightSponsor="'MEGATV'" />
      <AchievementModal ref="acv-hacker" :showModal="showAchievementHacker" :title="'L33T H@CK3R'" :leftSponsor="'INSP'" :rightSponsor="'SHORTZ'" />
      <AchievementModal ref="acv-mainframe" :showModal="showAchievementMainframe" :title="'Mainframe Complete'" :leftSponsor="'EPIX'" :rightSponsor="'MLT'"/>
      <AchievementModal ref="acv-robot" :showModal="showAchievementRobot" :title="'Robotic Upgrade'" :leftSponsor="'FOX'" :rightSponsor="'QVC'"/>
      <AchievementModal ref="acv-gm" :showModal="showAchievementGM" :title="'Game Master'" :leftSponsor="'CLEO'" :rightSponsor="'UNI'"/>
      <AchievementModal ref="acv-power" :showModal="showAchievementPower" :title="'Playing with Power'" :leftSponsor="'TNT'" :rightSponsor="'CN'"/>
      <AchievementModal ref="acv-overload" :showModal="showAchievementOverload" :title="'System Overload'" :leftSponsor="'FIRST'" :rightSponsor="'OUT'"/>

      <KeyModal :showModal="showDataKeyModal" :keyId="unlockedDataKey"/>

      <transition name="fade">
        <InfoModal
          v-if="showInfoModal"
          :showModal="showInfoModal" 
          :description="sponsorInfo ? sponsorInfo.description : ''" 
          :logoUrl="sponsorInfo ? sponsorInfo.logoName : ''"
          :videoId="sponsorInfo ? sponsorInfo.sizzleVideoId : ''"
          :sponsorId="sponsorInfo ? sponsorInfo.id : ''"/>
      </transition>

      <GlitchModal ref="glitch-back" :showModal="glitchBack" description="HINT: Click the camera icon to take a selfie in the photo booth. (IF YOU RECEIVE A BLOCKED WEBSITE WARNING, TRY DISCONNECTING FROM YOUR VPN)"/>
      <GlitchModal ref="glitch-hint-1" :showModal="glitchHint1" description="HINT: DECIPHER BINARY CODE HIDDEN IN THE DEVELOPER CONSOLE AND ENTER IN ACHIEVEMENTS"/>
      <GlitchModal ref="glitch-hint-2" :showModal="glitchHint2" description="HINT: DECIPHER MORSE CODE MESSAGE HIDDEN IN MUSIC TRACK AND ENTER IN ACHIEVEMENTS"/>
      <FinishedGameModal :showModal="finishedGame" :charityTotal="charityTotal" />
      
      <PrizeModal ref="glitch-prize" :showModal="glitchPrize || showGrandPrizeModal" :grandPrize="showGrandPrizeModal" :prize="true" :prizeSponsorId="glitchPrizeSponsor" :restricted="programmerRestricted" />
      <MiniGameModal ref="miniGameModal" :miniGameUrl="miniGameUrl" :showModal="showGameModal" :leftSponsor="leftSponsor" :rightSponsor="rightSponsor" />
      <TriviaModal :showModal="showTriviaModal" :triviaIndex="triviaIndex"/>

      <Tutorial v-if="!finishedTutorial" />
    </main>

    
  </div>
</template>

<script>
import Tutorial from './tutorial/Tutorial.vue'
import AchievementList from './GameAchievementList.vue'
import AchievementModal from './modal/AchievementModal.vue'
import InfoModal from './modal/InfoModal.vue'
import GlitchModal from './modal/GlitchModal.vue'
import MiniGameModal from './modal/MiniGameModal.vue'
import TriviaModal from './modal/TriviaModal.vue'
import PrizeModal from './modal/PrizeModal.vue'
import FinishedGameModal from './modal/FinishedGameModal.vue'
import KeyModal from './modal/KeyModal.vue'
import Photobooth from './GamePhotobooth.vue'
import SponsorList from './GameSponsorList.vue'
import Board from './GameBoard.vue'
import ExitButton from './GameExitButton.vue'
import MuteButton from './GameMuteButton.vue'
import { playerCollection, programmerCollection, increment } from '../firebase'

export default {
  name: 'Game',
  components: {
    Tutorial,
    AchievementList,
    AchievementModal,
    InfoModal,
    GlitchModal,
    FinishedGameModal,
    MiniGameModal,
    TriviaModal,
    PrizeModal,
    KeyModal,
    Photobooth,
    SponsorList,
    Board,
    MuteButton,
    ExitButton,
  },
  inject: ['sponsors', 'currentUser'],
  data() {
    return { 
      finishedTutorial: this.$route.query.skipTutorial || false, 
      suppressAutoMove: this.$route.query.suppressAutoMove || true,
      showInfoModal: false, 
      showGrandPrizeModal: false,
      showAchievementGlitch: false,
      showAchievementGM: false,
      showAchievementHacker: false,
      showAchievementMainframe: false,
      showAchievementOverload: false,
      showAchievementPower: false,
      showAchievementRobot: false,
      showGameModal: false, 
      showDataKeyModal: false,
      unlockedDataKey: '',
      showGlitch: false,
      glitchesRevealed: 0,
      sponsorInfo: null, 
      glitchBack: false, 
      glitchPrize: false, 
      glitchPrizeSponsor: '',
      glitchHint1: false,
      glitchHint2: false,
      showTriviaModal: false,
      triviaIndex: -1,
      leftSponsor: '',
      rightSponsor: '',
      startZone: '',
      avatarIndex: 0,
      spacesRevealed: 0,
      miniGamesRevealed: 0,
      zonesCompleted: 0,
      mainStageClicked: 0,
      charityTotal: 0,
      // bonusPrizes: [],
      bgAudio: null,
      wasMuted: false,
      miniGameUrl: '',
      loadingPlayerData: true,
      playerDocument: null,
      playerDocumentData: null,
      programmerRestricted: false,
      achievementBinary: false,
      achievementCracker: false,
      finishedGame: false,
    }
  },
  provide() {
    return { 
      'moveAvatarToSpace': this.moveAvatarToSpace,
      'showSponsorInfo': this.showSponsorInfo, 
      'hideSponsorInfo': this.hideSponsorInfo,
      'showRandomGlitch': this.showRandomGlitch,
      'hideGlitch': this.hideGlitch,
    }
  },
  mounted() {
    console.log('HINT: Code Cracker - Mobilizing Your World')
    this.$refs.avatar = this.$refs.board.$refs.avatar

    if (this.currentUser) {
      this.playerDocument = playerCollection.doc(this.currentUser.email)
      if (this.playerDocument) {
        this.playerDocument.get()
          .then(doc => {
            if (doc) {
              this.playerDocumentData = doc.data()
              this.finishedTutorial = this.playerDocumentData.finishedTutorial
              if (this.finishedTutorial) {
                this.showAchievementRobot = false
              }
              this.programmerRestricted = this.playerDocumentData.programmer
              this.glitchesRevealed = this.playerDocumentData.glitchesRevealed || 0
              this.miniGamesRevealed = this.playerDocumentData.miniGamesRevealed || 0
              this.zonesCompleted = this.playerDocumentData.zonesCompleted || 0
              this.mainStageClicked = this.playerDocumentData.mainStageClicked || 0
              this.charityTotal = this.playerDocumentData.charityTotal || 0
              this.avatarIndex = this.playerDocumentData.avatarIndex
              this.finishedGame = this.playerDocumentData.finishedGame || false
              if (this.playerDocumentData.startReference && this.playerDocumentData.spacesRevealed) {
                this.loadSpacesRevealed(this.playerDocumentData.startReference, this.playerDocumentData.spacesRevealed + 1)
              }
              // setTimeout(() => { this.loadingPlayerData = false }, 2500)
              // console.log(`Finished loading player doc for ${this.currentUser.email}. Restricted? ${this.programmerRestricted} - Spaces Revealed: ${this.spacesRevealed}`)
              this.loadingPlayerData = false
            }
          })
          .catch(error => {
            this.loadingPlayerData = false
            console.error(`CRITICAL! Failed to fetch player document for email: ${this.currentUser.email}. Details: ${error.message}`)
          })
      } else {
        console.error(`Failed to fetch player document for ${this.currentUser.email}`)
      }
    }
  },
  watch: {
    spacesRevealed(newSpaces) {
      if (newSpaces == 63) {
        this.showAchievementPower = true
      }
      if (newSpaces + Math.min(this.mainStageClicked, 8) == 71) {
        this.showAchievementOverload = true
      }

      // Prevent the load -> save loop that can occur on start
      if (this.loadingPlayerData) {
        return
      }

      // if (newSpaces % 3 == 0) {
        this.playerDocument.update({ spacesRevealed: Math.min(63, newSpaces) })
          .then(() => {
            console.log(`Updated spaces revealed in database to ${Math.min(63, newSpaces)}`)
          })
          .catch(error => {
            console.error(`Failed to updated spaces. Details: ${error.message}`)
          })
      // }
    },
    glitchesRevealed(newGlitches) {
      // console.log(`Updated glitches ${newGlitches}`)
      if (newGlitches == 3 && !this.loadingPlayerData) {
        this.showAchievementGlitch = true
      }

      if (!this.loadingPlayerData) {
        this.playerDocument.update({ glitchesRevealed: Math.min(newGlitches, 3) })
          .then(() => {
            console.log(`Updated glitches revealed in database to ${Math.min(newGlitches, 3)}`)
          })
          .catch(error => {
            console.error(`Failed to updated spaces. Details: ${error.message}`)
          })
      }
    },
    mainStageClicked(newClicked) {
      if (newClicked == 8) {
        this.showAchievementMainframe = true
        if (!this.loadingPlayerData) {
          this.playerDocument.update({ mainStageClicked: Math.min(newClicked, 8) })
            .then(() => {
              console.log(`Updated main stage clicked in database to ${Math.min(newClicked, 8)}`)
            })
            .catch(error => {
              console.error(`Failed to updated spaces. Details: ${error.message}`)
            })
        }
      }
      const oldTotal = Math.min(this.mainStageClicked, 8) + Math.min(this.spacesRevealed, 63)
      if (Math.min(newClicked, 8) + Math.min(this.spacesRevealed, 63) == 71 && oldTotal < 71) {
        this.showAchievementOverload = true
      }
    },
    showGameModal(newShow) {
      const muteButton = this.$refs['mute-button']

      if (newShow == false) {
        muteButton.$data.muted = this.wasMuted

        if (this.miniGamesRevealed == 7) {
          this.showAchievementGM = true
        }
        if (!this.loadingPlayerData) {
          this.playerDocument.update({ miniGamesRevealed: Math.min(this.miniGamesRevealed, 7) })
            .then(() => {
              console.log(`Updated mini games revealed in database to ${Math.min(this.miniGamesRevealed, 7)}`)
            })
            .catch(error => {
              console.error(`Failed to updated spaces. Details: ${error.message}`)
            })
        }
      } else {
        this.wasMuted = muteButton.$data.muted
        muteButton.$data.muted = true
      }
    },
    showInfoModal(newShow) {
      const muteButton = this.$refs['mute-button']

      if (newShow == false) {
        muteButton.$data.muted = this.wasMuted

        if (this.unlockedDataKey.length) {
          setTimeout(() => { this.showDataKeyModal = true }, 3500)
        }
      } else {
        this.wasMuted = muteButton.$data.muted
        muteButton.$data.muted = true
      }
    },
    showDataKeyModal(newShow) {
      if (newShow == false) {
        this.unlockedDataKey = ''
        if (this.zonesCompleted == 6) {
          this.showAchievementHacker = true
        }
        if (!this.loadingPlayerData) {
          this.playerDocument.update({ zonesCompleted: Math.min(this.zonesCompleted, 6) })
            .then(() => {
              console.log(`Updated zones completed in database to ${Math.min(this.zonesCompleted, 6)}`)
            })
            .catch(error => {
              console.error(`Failed to updated spaces. Details: ${error.message}`)
            })
        }
      }
    },
    showAchievementPower(newShow) {
      // They finished viewing the final achievement
      if (newShow == false) {
        if (!this.finishedGame) {
          // Show customized grand prize modal
          this.showGrandPrizeModal = true
        }
      }
    },
    finishedGame(newFinished) {
      if (newFinished) {
        console.log(`Show final finish game pop up`)
      }
    }
  },
  methods: {
    incrementInteractionCount(sponsorId) {
      programmerCollection.doc(sponsorId).update({ interactions: increment(1) })
        .then(() => {
          // console.log(`Incremented interaction count for sponsor ${sponsorId}`)
        })
        .catch((error) => {
          console.error(`Failed to increment interaction count for sponsor ${sponsorId}. Details: ${error.message}`)
        })
    },
    checkAchievementProgress() {
      const glitchesRevealed = this.glitchesRevealed >= 3
      const miniGames = this.miniGamesRevealed >= 7
      const dataKeys = this.zonesCompleted >= 6
      const spacesRevealed = this.spacesRevealed >= 63
      const mainFrame = this.mainStageClicked >= 8
      const allContent = this.spacesRevealed + Math.min(this.mainStageClicked, 8) >= 71
      return glitchesRevealed && miniGames && dataKeys && spacesRevealed && mainFrame && allContent
    },
    loadSpacesRevealed(startRef, revealedCount) {
      // console.log('Load spaces revealed')
      const board = this.$refs.board
      const spaces = board.$refs.boardSpaces.children
      // console.dir(spaces)
      var loadedCount = revealedCount
      var foundStart = false
      const startIndex = Array.prototype.findIndex.call(spaces, (spaceEl) => {
        const space = spaceEl['__vue__']
        return space.startRef == startRef
      });
      // console.log(`Start index of start ref: ${startRef} is (${startIndex})`)
      const leftHalf = Array.prototype.slice.call(spaces, startIndex)
      // console.log(`Left Half`)
      // console.dir(leftHalf)
      const rightHalf = Array.prototype.slice.call(spaces, 0, startIndex)
      // console.log(`Right Half`)
      // console.dir(rightHalf)
      const reorderedSpaces = Array.prototype.concat.call(leftHalf, rightHalf)
      // console.dir(reorderedSpaces)
      // console.log('iterating over spaces')
      reorderedSpaces.forEach(spaceEl => {
        if (loadedCount <= 0) {
          return;
        }
        const space = spaceEl['__vue__']
        // console.log(`Found space ${space.x},${space.y}`)
        // console.dir(space)
        if (space.startRef == startRef) {
          foundStart = true
          console.log('Found start')
        }
        if (foundStart) {
          console.log('Revealing space')
          space.revealed = true
          // reveal space
          loadedCount--
          if (loadedCount == 0) {
            space.moveToSpace(2500)
            return false
          }
        }
      })
      
      this.spacesRevealed = revealedCount
      this.startZone = startRef
    },
    moveToStartSpace(startSpaceName) {
      setTimeout(() => { this.showAchievementRobot = true }, 1500)
      // console.dir(this.$refs.board.$refs)
      const startSpace = this.$refs.board.$refs[startSpaceName][0]
      // console.dir(startSpace)
      startSpace.moveToSpace(2500)
    },
    moveToNextSpace() {
      this.loadSpacesRevealed(this.startZone, this.spacesRevealed)

      // // console.dir(this.$route.query)
      // if (this.suppressAutoMove) {
      //   return;
      // }
      
      // const avatar = this.$refs.avatar;
      // const currentSpaceEl = avatar.$data.space;
      // const currentSpace = currentSpaceEl['__vue__']
      // const currentSpaceIndex = currentSpace.$props.spaceIndex
      // // console.log('current space key ' + currentSpaceIndex)

      // var nextSpace
      // if (currentSpaceIndex >= 62) {
      //   // Wrap to first space
      //   nextSpace = this.$refs.board.$children.find(child => child.spaceIndex == 0);
      //   // console.log(`found space: ${foundSpace}`)
      // } else {
      //   nextSpace = currentSpaceEl.nextSibling['__vue__']
      // }
      
      // if (nextSpace) {
      //   nextSpace.moveToSpace()
      // }
    },
    moveToPreviousSpace() {

    },
    moveAvatarToSpace(x, y, direction, flipped, space, actionDelay = 850) {
      const vueSpace = space['__vue__']
      const avatar = this.$refs.avatar
      if (avatar.$data.moving) {
        console.log('Blocked attempted double move')
        return
      }

      const startLeft = avatar.$el.offsetLeft
      const startTop = avatar.$el.offsetTop
      const deltaX = startLeft - x - 30
      const deltaY = startTop - y - 66
      
      avatar.$data['x'] = x
      avatar.$data['y'] = y
      avatar.$data['direction'] = direction
      avatar.$data['flipped'] = flipped
      avatar.$data['space'] = space
      avatar.$data['moving'] = true

      var animation = avatar.$el.animate({
        transform: [`translate(${deltaX}px, ${deltaY}px)`, 'none'],
      }, {
        direction: 'normal',
        duration: (this.$route.debugSlowAnimations ? 2500 : 750),
        iterations: 1,
        easing: 'ease-out',
      });

      
      var ctx = this
      animation.onfinish = () => {
        // We don't reveal the tile until the move is complete
        if (!vueSpace.$data.revealed) {
          vueSpace.$data.revealed = true;
          vueSpace.$data.newReveal = true;
          console.log(`ctx.spacesRevealed = ${ctx.spacesRevealed}`)
          ctx.spacesRevealed = Math.min(ctx.spacesRevealed + 1, 63)
        }

        avatar.$data['moving'] = false
        // give some time to process animation
        setTimeout(() => ctx.handleActionForSpace(space), actionDelay)
      }
      animation.play()
    },
    handleActionForSpace(space) {
      const vueSpace = space['__vue__']
      // console.dir(vueSpace.$props)

      if (vueSpace.$props.glitch && !vueSpace.$props.revealed) {
        // console.log('Glitch in the matrix')
        this.showRandomGlitch()
        this.glitchesRevealed++
      } else if (vueSpace.$props.miniPrize && !vueSpace.$props.revealed) {
        // console.log('Mini Prize wheel!')
        if (vueSpace.$data.newReveal) {
          this.glitchPrize = true
          this.glitchPrizeSponsor = vueSpace.$props.sponsorId
          this.showGlitch = true
          // const findSponsorId = vueSpace.$props.sponsorId
          // console.dir(this.sponsors.find(sponsor => sponsor.id == findSponsorId))
          //this.bonusPrizes = this.sponsors.find(sponsor => sponsor.id == findSponsorId).prizes
        }
        
      } else if (vueSpace.$props.trivia) {
        // console.log('Trivia discovered')
        this.triviaIndex++
        this.showTriviaModal = true
      } else if (vueSpace.$props.game && !vueSpace.$props.revealed) {
        // console.log('Mini Game triggered!')
        // console.dir(vueSpace.$props)
        this.leftSponsor = vueSpace.$props.sponsorLeft
        this.rightSponsor = vueSpace.$props.sponsorRight
        this.miniGameUrl = vueSpace.$props.miniGameUrl
        //this.$refs.miniGameModal.$data.leftSponsor = vueSpace.$props.leftSponsor
        //this.$refs.miniGameModal.$data.rightSponsor = vueSpace.$props.rightSponsor
        this.showGameModal = true
        this.incrementInteractionCount(this.leftSponsor)
        this.incrementInteractionCount(this.rightSponsor)
        this.miniGamesRevealed = Math.min(this.miniGamesRevealed + 1, 7) 
      } else if (vueSpace.$props.skip) {
        this.moveToNextSpace()
      } else {
        this.showSponsorInfo(vueSpace.$props.sponsorId)
        this.incrementInteractionCount(vueSpace.$props.sponsorId)

        if (vueSpace.$props.dataKey && this.spacesRevealed > 2 && vueSpace.$data.newReveal) {
          // console.log(`Show zone key unlocked ${vueSpace.$props.dataKey}`)
          this.unlockedDataKey = vueSpace.$props.dataKey
        }
      }
      vueSpace.$data.newReveal = false
      vueSpace.$data.revealed = true
    },
    showSponsorInfo(sponsorId) {
      var sponsor = this.sponsors.find(sponsor => sponsor.id == sponsorId)
      if (!sponsor) {
        return
      }

      //console.log(`Show info for sponsor ${JSON.stringify(sponsor)}`)
      this.sponsorInfo = sponsor
      this.showInfoModal = true
    },
    hideSponsorInfo() {
      this.sponsorInfo = null
      this.showInfoModal = false
    },
    showRandomGlitch() {
      // console.log(this.$refs['glitch-back'].$props);
      // hideGlitch()
      const random = Math.ceil(Math.random() * 4)
      if (random == 1) {
        this.glitchBack = true
      } else if (random == 2) {
        this.glitchHint1 = true
      } else if (random == 3) {
        this.glitchHint2 = true
      } else {
        this.glitchHint2 = true
      }
      
      this.showGlitch = true
    },
    hideGlitch() {
      this.glitchDouble = false
      this.glitchPrize = false
      this.glitchBack = false
      this.glitchHint1 = false
      this.glitchHint2 = false
      this.showGrandPrizeModal = false
      this.showGlitch = false
    },
    hideAchievementModal() {
      this.showAchievementGlitch = false
      this.showAchievementGM = false
      this.showAchievementHacker = false
      this.showAchievementMainframe = false
      this.showAchievementOverload = false
      this.showAchievementPower = false
      this.showAchievementRobot = false
    }
  }
}
</script>
