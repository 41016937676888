<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="mt-24 mx-64">
      <div class="flex flex-col">
        <button v-on:click="checkMagicLinks" class="btn bg-game-blue hover:bg-game-blue-light">Query Magic Links</button>
      </div>
      <div class="flex flex-col">
        <h3 class="h3 py-2">({{ prizes.length }}) Prizes</h3>
        <ul class="list-decimal">
          <li v-for="prize in prizes" :key="prize.id" :class="{'font-extrabold': prize.winnerEmail }">
            
            <p>
              <FontAwesomeIcon v-if="prize.winnerEmail" icon="check-double" />
              "{{ prize.name }}" {{ prize.winnerEmail ? `- Winner: ${prize.winnerEmail}` : '' }}
            </p>
          </li>
        </ul>
      </div>

      <div class="flex flex-col">
        <h3 class="h3 py-2">({{ players.length }}) Players</h3>
        <ul>
          <li v-for="player in players" :key="player.id">
            {{ player.name }}
          </li>
        </ul>
      </div>

      <div class="flex flex-col">
        <h3 class="h3 py-2">({{ programmers.length }}) Programmers <button v-on:click="importProgrammers" class="btn bg-game-blue hover:bg-game-blue-light">Import Data</button></h3>
        <ul>
          <li v-for="programmer in programmers" :key="programmer.id">
            {{ programmer.name }}
          </li>
        </ul>
      </div>

      <!-- <div class="flex flex-col">
        <div class="flex flex-row justify-between">
          <h3 class="h3">Static Data</h3>
        </div>
        <ul>
          <li v-for="sponsor in sponsorsStatic" :key="sponsor.id">
            {{ sponsor.short_name }}
          </li>
        </ul>
      </div> -->
    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import { arrayUnion, auth, db, playerCollection, programmerCollection, prizeCollection } from './../firebase.js'
import StaticJSON from './../game/sponsors.js'
import Footer from './../partials/Footer.vue'

export default {
  data() {
    return {
      prizes: [],
      players: [],
      programmers: [],
      sponsorsStatic: StaticJSON["sponsors"],
      programmersStatic: StaticJSON["programmers"],
    }
  },
  name: 'Database',
  components: {
    Header,
    Footer,
  },
  computed: {
    isAuthorized() {
      return auth.currentUser != null
    },
  },
  methods: {
    checkMagicLinks() {
      db.collection('emails').where('delivery.state', '==', 'SUCCESS').get()
        .then((emailSnapshot) => {
          console.log(`Found ${emailSnapshot.size} successful emails`)
        })
        .catch((error) => {
          console.error(`Failed to query delivery success emails. Details: ${error.message}`)
        })
      
      db.collection('emails').where('delivery.state', '==', 'ERROR').get()
        .then((emailSnapshot) => {
          console.log(`Found ${emailSnapshot.size} error emails`)
        })
        .catch((error) => {
          console.error(`Failed to query delivery error emails. Details: ${error.message}`)
        })
    },
    importProgrammers() {
      console.log(`Starting import of ${this.sponsorsStatic.length} records...`)

      this.sponsorsStatic.forEach((staticSponsor) => {
        var sponsorData = {
            name: staticSponsor.name,
            displayName: staticSponsor.short_name,
            tier: staticSponsor.tier,
            logoName: staticSponsor.logoName,
            description: staticSponsor.description,
            interactions: 0,
            sizzleVideoId: staticSponsor.sizzleVideoId
        }
        if (staticSponsor.mainStageVideoId) {
          sponsorData.mainStageVideoId = staticSponsor.mainStageVideoId
        }

        programmerCollection.doc(staticSponsor.id).set(sponsorData, { merge: true })
          .then(function() {
            console.log(`Successfully updated sponsor: ${staticSponsor.id}`)
          })
          .catch(function(error) {
            console.error(`Failed to updat sponsor: ${staticSponsor.id}. Details: ${error.message}`)
          });

        // Import prizes
        if (staticSponsor.prizes && staticSponsor.prizes.length) {
          staticSponsor.prizes.forEach(prizeName => {
            const prizeData = {
              // id: index + '-' + staticSponsor.id,
              name: prizeName,
              winnerEmail: null
            }
            
            programmerCollection.doc(staticSponsor.id).collection('prizes').doc().set(prizeData)
              .then(() => {
                console.log(`Successfully updated prizes for sponsor ${staticSponsor.id}`)
              })
              .catch((error) => {
                console.error(`Failed to update prizes for sponsor ${staticSponsor.id}. Details: ${error.message}`)
              })
          })
        }
      })

      this.programmersStatic.forEach(programmerInfo => {
        programmerCollection.doc(programmerInfo.sponsorId)
          .update({
            emails: arrayUnion(programmerInfo.email.toLowerCase())
          })
          .then(() => {
            console.log(`Added programmer ${programmerInfo.email} to sponsor ${programmerInfo.sponsorId}`)
          })
          .catch((error) => {
            console.error(`Failed to add programmer ${programmerInfo.email} to sponsor ${programmerInfo.sponsorId}. Details: ${error.message}`)
          })
      });
    }
  },
  mounted() {
    const vm = this

    playerCollection.get().then((snapshot) => {
      snapshot.forEach(player => { vm.players.push(player.data())})
      // console.log(`Players: ${vm.players}`)
    })

    programmerCollection.get().then((snapshot) => {
      snapshot.forEach(programmer => { vm.programmers.push(programmer.data())})
      // console.log(`Programmers: ${vm.programmers}`)
    })

    prizeCollection.get().then((snapshot) => {
      snapshot.forEach(prize => { vm.prizes.push(prize.data())})
      // console.log(`Prizes: ${vm.prizes}`)
    })
  }
}
</script>
