<template>
    <section v-bind:class="{ 'opacity-0': finishedTutorial }" class="transition-opacity duration-1000 ease-in-out fixed inset-0 w-screen h-screen z-50 overflow-hidden p-10 bg-game-dark">
      <div class="fixed inset-0 text-game-blue" :style="backgroundStyle">
          <div class="flex flex-col justify-between max-w-4xl mt-48 mx-auto p-6" style="height: 560px;">
            <div class="flex flex-col justify-between h-full mb-2 pl-1">
                <slot name="content">
                    <h3 v-if="stepIndex == 0" class="pt-16 pb-4 h3 text-center text-game-blue-light my-auto uppercase">[Loading...]</h3>
                    <div v-if="stepIndex == 0" class="flex-col flex-grow py-12 px-12">
                      <p class="py-2 text-game-blue-light my-auto text-xl uppercase animate-pulse">TO ZOOM IN AND OUT OF THE BOARD, USE "CTRL +" AND "CTRL -" OR "CMD +" AND "CMD -" ON A MAC.</p>
                    </div>

                    
                    <h3 v-if="stepIndex == 1" class="py-4 h3 text-center text-game-blue-light my-auto uppercase">Welcome to</h3>
                    <h2 v-if="stepIndex == 1" class="pt-0 pb-4 h2 text-center text-game-blue-light my-auto uppercase">[AT&amp;T Programmer Fair]</h2>
                    <div v-if="stepIndex == 1" class="flex-col flex-grow py-12 px-12">
                        <p class="py-2 text-game-blue-light my-auto text-xl uppercase">THIS IS AN INTERACTIVE BOARD GAME EXPERIENCE.  MOVE AROUND THE BOARD TO ACTIVATE ALL OF THE POWER CELLS (GAME TILES) IN EACH ZONE.  POWER UP ALL SIX ZONES AND COMPLETE ALL ACHIEVEMENTS TO UNLOCK THE MAIN PRIZE RANDOMIZER.</p>
                        <p class="py-2 text-game-blue-light my-auto text-xl uppercase">GOOD LUCK USER, AND WELCOME TO OUR WORLD OF CONTENT...</p>
                    </div>
                    <button v-on:click="nextStep" v-if="stepIndex == 1" class="animate-pulse mb-8 btn btn-sm mx-auto w-40 rounded-full hover:bg-game-blue hover:text-game-dark bg-game-blue-light text-game-dark uppercase">Continue</button>

                    <h3 v-if="stepIndex == 2" class="py-8 h3 flex-grow text-center text-game-blue-light my-auto uppercase">[Terms &amp; Conditions]</h3>
                    <div v-if="stepIndex == 2" class="flex-col flex-grow px-12">
                        <p class="py-1 text-game-blue-light my-auto text-xl uppercase">THIS GAME IS SUBJECT TO THE TERMS OF USE AND PRIVACY POLICY, LOCATED ON THE PROGRAMMER FAIR HOME PAGE.</p>
                        <p class="py-1 text-game-blue-light my-auto text-xl uppercase">BY CLICKING CONTINUE, YOU AGREE TO THE PRIZE RANDOMIZER OFFICIAL RULES, LOCATED ON THE PROGRAMMER FAIR FAQ PAGE.</p>
                        <p class="py-1 text-game-blue-light my-auto text-xl uppercase">YOU MAY REFER BACK TO THESE RULES AT ANY TIME.</p>
                    </div>
                    <button v-on:click="nextStep" v-if="stepIndex == 2" class="animate-pulse mb-8 btn btn-sm mx-auto w-40 rounded-full hover:bg-game-blue hover:text-game-dark bg-game-blue-light text-game-dark uppercase">Continue</button>

                    <h3 v-if="stepIndex == 3" class="py-8 h3 flex-grow text-center text-game-blue-light my-auto uppercase">[HOW TO PLAY]</h3>
                    <div v-if="stepIndex == 3" class="flex-col flex-grow px-12">
                        <p class="py-1 text-game-blue-light my-auto text-xl uppercase" style="text-indent: hanging;">&bull; TO ZOOM IN AND OUT OF THE BOARD, USE "CTRL +" AND "CTRL -" OR "CMD +" AND "CMD -" ON A MAC.</p>
                        <p class="py-1 text-game-blue-light my-auto text-xl uppercase" style="text-indent: hanging;">&bull; CLICK THE NEXT CLOCKWISE CELL ON THE BOARD TO MOVE FORWARD.</p>
                        <p class="py-1 text-game-blue-light my-auto text-xl uppercase" style="text-indent: hanging;">&bull; WATCH THE PROGRAMMER VIDEOS ON EACH CELL AND ON THE MAIN STAGE, PLAY MINI-GAMES, SPIN PRIZE RANDOMIZERS AND FIND OTHER SURPRISES TO COMPLETE THE GAME.</p>
                        <p class="py-1 text-game-blue-light my-auto text-xl uppercase" style="text-indent: hanging;">&bull; TRACK YOUR ACHIEVEMENTS BY CLICKING ON THE TROPHY ICON.</p>
                    </div>
                    <button v-on:click="nextStep" v-if="stepIndex == 3" class="animate-pulse mb-8 btn btn-sm mx-auto w-40 rounded-full hover:bg-game-blue hover:text-game-dark bg-game-blue-light text-game-dark uppercase">Continue</button>

                    <h3 v-if="stepIndex == 4" class="py-8 h3 flex-grow text-center text-game-blue-light my-auto uppercase">[HOW TO PLAY CONTINUED]</h3>
                    <div v-if="stepIndex == 4" class="flex-col flex-grow px-12">
                        <p class="py-1 text-game-blue-light my-auto text-xl uppercase" style="text-indent: hanging;">&bull; IF THE GAME MUSIC INTERFERES WITH THE SOUND OF THE VIDEOS, CLICK ON THE MUSIC ICON TO SILENCE IT.</p>
                        <p class="py-1 text-game-blue-light my-auto text-xl uppercase" style="text-indent: hanging;">&bull; TAKE A SELFIE BY CLICKING ON THE PHOTO ICON.</p>
                        <p class="py-1 text-game-blue-light my-auto text-xl uppercase" style="text-indent: hanging;">&bull; SEARCH FOR ANY PROGRAMMER BY CLICKING ON THE MAGNIFYING GLASS ICON.</p>
                        <p class="py-1 text-game-blue-light my-auto text-xl uppercase" style="text-indent: hanging;">&bull; IF YOU EXIT THE GAME, YOUR PROGRESS WILL BE SAVED, IF YOU RE-ENTER ON THE SAME COMPUTER.</p>
                    </div>
                    <button v-on:click="nextStep" v-if="stepIndex == 4" class="animate-pulse mb-8 btn btn-sm mx-auto w-40 rounded-full hover:bg-game-blue hover:text-game-dark bg-game-blue-light text-game-dark uppercase">Continue</button>


                    <h3 v-if="stepIndex == 5" class="mt-4 py-4 h3 text-center text-game-blue-light uppercase">[Choose your Robot]</h3>
                    <div v-if="stepIndex == 5" class="grid grid-cols-3 grid-rows-2 place-items-center px-32 gap-y-4 gap-x-0">
                        <div v-bind:class="{'opacity-100': avatarIndex == 0 }" v-on:click="avatarIndex = 0" class="opacity-50 justify-self-end"><img :src="require('@/images/game/avatar/Avatar_Blue.svg')"></div>
                        <div v-bind:class="{'opacity-100': avatarIndex == 1 }" v-on:click="avatarIndex = 1" class="opacity-50 "><img :src="require('@/images/game/avatar/Avatar_Purple.svg')"></div>
                        <div v-bind:class="{'opacity-100': avatarIndex == 2 }" v-on:click="avatarIndex = 2" class="opacity-50 justify-self-start"><img :src="require('@/images/game/avatar/Avatar_Orange.svg')"></div>
                        <div v-bind:class="{'opacity-100': avatarIndex == 3 }" v-on:click="avatarIndex = 3" class="opacity-50 justify-self-end"><img :src="require('@/images/game/avatar/Avatar_Green.svg')"></div>
                        <div v-bind:class="{'opacity-100': avatarIndex == 4 }" v-on:click="avatarIndex = 4" class="opacity-50 "><img :src="require('@/images/game/avatar/Avatar_Teal.svg')"></div>
                        <div v-bind:class="{'opacity-100': avatarIndex == 5 }" v-on:click="avatarIndex = 5" class="opacity-50 justify-self-start"><img :src="require('@/images/game/avatar/Avatar_Grey.svg')"></div>
                    </div>
                    <button v-on:click="nextStep" v-if="stepIndex == 5" class="animate-pulse mb-8 btn btn-sm mx-auto w-40 rounded-full hover:bg-game-blue hover:text-game-dark bg-game-blue-light text-game-dark uppercase">Continue</button>


                    <h3 v-if="stepIndex == 6 && placeIndex == -1" class="mt-4 py-4 h3 text-center text-game-blue-light uppercase">[Randomizing Your Start]</h3>
                    <img v-if="stepIndex == 6 && placeIndex == -1" class="w-64 h-64 mx-auto" :src="require('@/images/game/tutorial/EmptyPlace.png')">
                    <h3 v-if="stepIndex >= 6 && placeIndex == 1" class="mt-4 py-4 h3 text-center text-game-blue-light uppercase">[Family &amp; Kids]</h3>
                    <img v-if="stepIndex >= 6 && placeIndex == 1" class="w-64 h-64 mx-auto" :src="require('@/images/game/tutorial/FamilyKids.png')">
                    <h3 v-if="stepIndex >= 6 && placeIndex == 2" class="mt-4 py-4 h3 text-center text-game-blue-light uppercase">[International]</h3>
                    <img v-if="stepIndex >= 6 && placeIndex == 2" class="w-64 h-64 mx-auto" :src="require('@/images/game/tutorial/International.png')">
                    <h3 v-if="stepIndex >= 6 && placeIndex == 3" class="mt-4 py-4 h3 text-center text-game-blue-light uppercase">[Movies &amp; Original Content]</h3>
                    <img v-if="stepIndex >= 6 && placeIndex == 3" class="w-64 h-64 mx-auto" :src="require('@/images/game/tutorial/MoviesOriginalContent.png')">
                    <h3 v-if="stepIndex >= 6 && placeIndex == 4" class="mt-4 py-4 h3 text-center text-game-blue-light uppercase">[Entertainment &amp; Music]</h3>
                    <img v-if="stepIndex >= 6 && placeIndex == 4" class="w-64 h-64 mx-auto" :src="require('@/images/game/tutorial/MusicEntertainment.png')">
                    <h3 v-if="stepIndex >= 6 && placeIndex == 5" class="mt-4 py-4 h3 text-center text-game-blue-light uppercase">[News &amp; Information]</h3>
                    <img v-if="stepIndex >= 6 && placeIndex == 5" class="w-64 h-64 mx-auto" :src="require('@/images/game/tutorial/NewsInformation.png')">
                    <h3 v-if="stepIndex >= 6 && placeIndex == 6" class="mt-4 py-4 h3 text-center text-game-blue-light uppercase">[Sports]</h3>
                    <img v-if="stepIndex >= 6 && placeIndex == 6" class="w-64 h-64 mx-auto" :src="require('@/images/game/tutorial/Sports.png')">
                    <button v-on:click="startRandomizingPlace" v-if="stepIndex >= 6 && placeIndex == -1" class="mb-8 btn btn-sm mx-auto w-48 rounded-full hover:bg-game-blue hover:text-game-dark bg-game-blue-light text-game-dark uppercase">Calculate Start</button>
                    <button v-on:click="nextStep" v-if="stepIndex >= 6 && placeIndex != -1" v-bind:class="{ 'opacity-0': !doneRandomizing }" class="transition-opacity ease-in-out duration-500 mb-8 btn btn-sm mx-auto w-40 rounded-full hover:bg-game-blue hover:text-game-dark bg-game-blue-light text-game-dark uppercase">Enter World</button>
                </slot>
            </div>
          </div>
      </div>
    </section>
</template>

<script>
export default {
  name: 'Tutorial',
  props: [],
  data() {
    return { 
      stepIndex: 0, 
      placeIndex: -1, 
      doneRandomizing: false,
      randomizeIntervalRef: null,
      avatarIndex: 4, 
      startReferences: ['startFamily', 'startFamily', 'startInternational', 'startMovies', 'startEntertainment', 'startNews', 'startSports'],
    }
  },
  computed: {
    backgroundStyle() {
      return { 
        'background': 'center url(' + require('@/images/game/ui/bg-large-panel.svg') + ') no-repeat',
        'background-size': '1240px 875px',
        'height': 875 + 'px',
      }
    },
    finishedTutorial() {
        return this.stepIndex == 7
    }
  },
  mounted() {
      setTimeout(() => { this.stepIndex = 1 }, Math.random() * 2000 + 9500)
  },
  methods: {
      nextStep() {
				this.stepIndex++
				// console.log(`next step ${this.stepIndex}`)
				if (this.stepIndex == 3) {
					this.$parent.$data.avatarIndex = this.avatarIndex
				} else if (this.finishedTutorial) {
          // console.log(`Finished tutorial`)
          if (this.$parent.$data.playerDocument) {
            this.$parent.$data.playerDocument.update({ finishedTutorial: true, avatarIndex: this.avatarIndex, startReference: this.startReferences[this.placeIndex], spacesRevealed: 0, charityTotal: 0 })
              .then(() => {
                // console.log(`Saved tutorial status to database`)
              })
              .catch((error) => {
                console.error(`Failed to save tutorial status to the database. Details: ${error.message}`)
              })
          }
          this.$parent.$data.avatarIndex = this.avatarIndex
          this.$parent.$data.finishedTutorial = true
          this.$parent.moveToStartSpace(this.startReferences[this.placeIndex])
          // setTimeout(this.$parent.moveToStartSpace, 8500)
				}
      },
      startRandomizingPlace() {
				this.randomizeIntervalRef = setInterval(this.randomizePlace, 150)
				setTimeout(() => { 
          this.doneRandomizing = true
          clearInterval(this.randomizeIntervalRef) 
          this.randomizeIntervalRef = null
				}, Math.random() * 2000 + 3500)
      },
      randomizePlace() {
				this.placeIndex = Math.floor(1 + Math.random() * 6)
      }
  },
  components: {
  },
}
</script>
