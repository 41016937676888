<template>
  <GameModal :showModal="showModal" :dismissFunc="onHidePrize" :modalBackgroundUrl="require('@/images/game/ui/bg-glitch-no-x.svg')">
    
    <template v-slot:logo>
      <img v-if="grandPrize" class="mx-auto w-20 mt-6" :src="require('@/images/game/buttons/icon-achievement.svg')"/>
      <SponsorLogo v-if="prizeSponsorId && !grandPrize" class="-mt-2 w-32 h-32" :sponsorId="prizeSponsorId" />
    </template>

    <template v-slot:content>
      <div v-if="prize && restricted" class="flex flex-col justify-center h-full text-center">
        <h3 class="h3 uppercase tracking-wider">Account Restricted from Prizes</h3>        
      </div>
      <div v-if="prize && !restricted" class="flex flex-col justify-between h-full text-center">
        <h3 class="h3 uppercase tracking-wider">[{{ modalTitle }}]</h3>        

        <div v-if="!showFinalMessage" class="flex flex-col justify-center -mt-2">
          <div ref="grandPrizeLogo" class="max-w-xs mx-auto" :class="{ hidden: !(grandPrize && !charityPrize)}">
            <SponsorLogo v-for="sponsor in sponsors" :key="sponsor.id" class="pt-12 pb-6 mb-2" :class="{ hidden: grandPrizeSponsorId != sponsor.id}" :sponsorId="sponsor.id" :isSquare="false" :smallHeight="true" />
          </div>
          
          <SponsorLogo v-if="!charityPrize && !grandPrize" :key="'logoImage'" class="max-w-xs mx-auto pt-12 pb-6 mb-2" :sponsorId="computedPrizeSponsorId" :isSquare="false" :smallHeight="true" />
          <img ref="charityPrizeRef" :key="'prizeImage'" :class="{hidden: !charityPrize }" class="max-w-xs mx-auto" :src="require('@/images/game/tutorial/CharityStar.png')" />

          <transition name="fade">
            <p ref="prizeNameRef" :prizeName="prizeName" :class="{ hidden: randomIterations == 0 }" class="font-bold text-xl py-4 justify-self-end flex-grow transition-opacity mx-auto">{{ prizeName }}</p>
          </transition>
        </div>

        <p v-if="showFinalMessage" class="uppercase tracking-wider text-center text-xl">{{ finalMessageText }}</p>
      
        <div class="flex flex-row pb-4 h-16">
          <button v-on:click.prevent="onClickedComputePrize" v-if="!prizeInterval" class="btn btn-sm mx-auto px-8 rounded-full hover:bg-game-blue hover:text-game-dark bg-game-blue-light text-game-dark text-lg uppercase">
            {{ prizeButtonText }}
          </button>
        </div>
      </div>
    </template>
  </GameModal>
</template>

<script>
import GameModal from './GameModal.vue'
import SponsorLogo from '../GameSponsorLogo.vue'
import { programmerCollection, playerCollection, increment } from './../../firebase.js'

export default {
  name: 'PrizeModal',
  props: ['showModal', 'prize', 'prizeSponsorId', 'restricted', 'grandPrize'],
  inject: ['sponsors', 'hideGlitch', 'currentUser'],
  data() {
    return { 
      hiding: false, 
      selectedPrize: false, 
      randomIndex: -1, 
      randomIterations: 0, 
      prizeInterval: null, 
      grandPrizeSponsorIndex: 0,
      charityNames: ['Employee Disaster Relief Fund', 'Feeding America', 'Save the Children'], 
      charityPrize: false,
      showFinalMessage: false,
      charityIndex: 0,
      bonusPrizes: [],
      prizeIdentifiers: [],
      winner: false,
    }
  },
  computed: {
    modalTitle() {
      if (this.grandPrize) {
        return 'Final Prize Randomizer'
      }
      return 'Prize Randomizer Unlocked'
    },
    finalMessageText() {
      if (!this.winner) {
        return `Thank you, AT&T will donate an additional $${this.donationAmount} towards charity on your behalf!`
      }
      return 'Congratulations! We’ve recorded your winning item, and you will receive an email with more prize information by Friday, December 18.'
    },
    prizesForSponsor() {
      if (this.grandPrize) {
        return this.sponsors[this.grandPrizeSponsorIndex].prizes || []
      }
      return this.bonusPrizes
    },
    donationAmount() {
      if (this.grandPrize) {
        return 5
      }
      return 1
    },
    computedPrizeSponsorId() {
      if (this.grandPrize) {
        return this.grandPrizeSponsorId
      }
      return this.prizeSponsorId
    },
    prizeCount() {
      if (this.grandPrize) {
        return this.prizesForSponsor.length
      }
      if (this.bonusPrizes) {
        return this.bonusPrizes.length
      }
      return 0
    },
    requiredIterations() {
      if (this.grandPrize) {
        return this.sponsors.length - 1
      }
      return 30
    },
    prizeName() {
      if (this.charityPrize) {
        return `Donation to ${this.charityName}!`
      }

      if (!this.prizeCount) {
        return ''
      }      

      if (this.grandPrize) {
        return this.prizesForSponsor[this.randomIndex]
      }
      return this.bonusPrizes[this.randomIndex]
    },
    charityName() {
      return this.charityNames[this.charityIndex]
    },
    grandPrizeSponsorId() {
      return this.sponsors[this.grandPrizeSponsorIndex].id
    },
    prizeButtonText() {
      if (this.randomIterations >= this.requiredIterations) {
        if (this.showFinalMessage) {
          return 'Continue'
        } else if (this.charityPrize) {
          return `Donate $${this.donationAmount}`
        }
        return 'Claim Prize'
      }
      return 'Compute Prize'
    }
  },
  methods: {
    onHidePrize() {
      // console.log('onHidePrize')
      if (this.restricted) {
        this.hideGlitch()
        this.resetData()
      } else {
        if (this.selectedPrize && !this.showFinalMessage) {
          this.$parent.$data.charityTotal += this.donationAmount
          this.showFinalMessage = true
        } else if (this.selectedPrize && this.showFinalMessage) {
          this.hideGlitch()
          this.resetData()
        }
      }
    },
    claimPrizeWithIdentifier(prizeIdentifier) {
      programmerCollection.doc(this.prizeSponsorId).collection('prizes').doc(prizeIdentifier).update({ winnerEmail: this.currentUser.email }, { merge: true })
        .then(() => {
          console.log(`Updated prize ${prizeIdentifier} with winner email: ${this.currentUser.email}`)
        })
        .catch((error) => {
          console.error(`Failed to claim prize ${prizeIdentifier} for winner: ${this.currentUser.email}. Details: ${error.message}`)
        })
    },
    fetchPrizesForSponsor(sponsorId) {
      programmerCollection.doc(sponsorId).collection('prizes').where('winnerEmail', '==', null).get()
        .then((prizeSnapShot) => {
          if (!prizeSnapShot.empty) {
            var transformedPrizes = []
            var transformedIdentifiers = []
            prizeSnapShot.docs.forEach((prizeDocSnapShot) => {
              const prizeData = prizeDocSnapShot.data()
              if (prizeData) {
                transformedPrizes.push(prizeData.name)
                transformedIdentifiers.push(prizeDocSnapShot.id)
              }
            })
            this.bonusPrizes = transformedPrizes
            this.prizeIdentifiers = transformedIdentifiers
            // console.dir(this.prizeIdentifiers)
            // const prizeData = prizeSnapShot.data()
            console.log(`Fetched ${prizeSnapShot.size} prizes for sponsor ${sponsorId}`)
          }
        })
        .catch((error) => {
          console.error(`Failed to fetch prizes for sponsor: ${sponsorId}. Details: ${error.message}`)
        })
    },
    resetData() {
      this.selectedPrize = false
      this.randomIndex = -1
      this.randomIterations = 0
      this.charityPrize = false
      this.charityIndex = 0
      this.prizeInterval = null
      this.grandPrizeSponsorIndex = 0
      this.showFinalMessage = false
      this.bonusPrizes = []
      this.prizeIdentifiers = []
    },
    onClickedComputePrize() {
      if (!this.selectedPrize) {
        this.prizeInterval = setInterval(this.animatePrizes, 125)
      }      
      return false
    },
    forceCharityPrize() {
      this.charityIndex = Math.floor(Math.random() * this.charityNames.length)
      this.charityPrize = true
    },
    animatePrizes() {
      this.randomIterations++
      // Show sponsor and prize every other roll
      const shouldStepPrize = this.grandPrize ? this.randomIterations % 4 != 0 : this.randomIterations % 2 == 0
      if (shouldStepPrize) {
        this.charityPrize = false
        this.randomIndex = Math.floor(Math.random() * this.prizeCount)

        if (this.grandPrize) {
          if (this.grandPrizeSponsorIndex < this.sponsors.length - 1) {
            this.grandPrizeSponsorIndex++
            const sponsorPrizes = this.sponsors[this.grandPrizeSponsorIndex].prizes
            if (sponsorPrizes) {
              this.randomIndex = Math.floor(Math.random() * this.prizeCount)
            } else {
              this.forceCharityPrize()
            }
          } else {
            this.forceCharityPrize()
          }
        }
      } else {
        this.forceCharityPrize()
      }
      
      // console.log(`Random index: ${this.randomIndex} - Charity index: ${this.charityIndex}`)
      const doneWithPrize = this.randomIterations >= this.requiredIterations && !this.grandPrize
      const doneWithGrandPrize = this.grandPrizeSponsorIndex >= this.requiredIterations && this.grandPrize
      if (doneWithPrize || doneWithGrandPrize) {
        if (Math.floor(Math.random() * 10) != 0 || this.prizeCount == 0 || this.grandPrize) {
        // if (this.prizeCount == 0) {
          this.forceCharityPrize()
          playerCollection.doc(this.currentUser.email).update({ charityTotal: increment(this.donationAmount), finishedGame: this.grandPrize })
            .then(() => {
              console.log(`Incremented charity total for user ${this.currentUser.email} by $${this.donationAmount}`)
            })
            .catch((error) => {
              console.error(`Failed to increment charity total. Details: ${error.message}`)
            })
            if (this.grandPrize) {
              this.$parent.$data.finishedGame = true
            }
        } else {
          this.winner = true
          this.charityPrize = false
          this.claimPrizeWithIdentifier(this.prizeIdentifiers[this.randomIndex])
        }
        clearInterval(this.prizeInterval)
        this.prizeInterval = null
        this.selectedPrize = true
        // console.log('Finished prize anim')
      } else {
        this.flashPrizeText()
      }
    },
    flashPrizeText() {
      this.$refs.prizeNameRef.animate({
        opacity: [`0`, '100%'],
        // transform: ['translateX(-5%)', 'translateX(10%)']
      }, {
        direction: 'normal',
        duration: 100,
        iterations: 1,
        easing: 'linear',
      })

      if (this.charityPrize) {
        this.$refs.charityPrizeRef.animate({
          opacity: [`0`, '100%'],
          // transform: ['translateX(-5%)', 'translateX(10%)']
        }, {
          direction: 'normal',
          duration: 100,
          iterations: 1,
          easing: 'ease-in-out',
        })
      }

      // Animate logos for the grand prize
      if (this.grandPrize) {
        if (!this.charityPrize) {
          this.$refs.grandPrizeLogo.animate({
            opacity: [`0`, '100%'],
            // transform: ['translateX(-5%)', 'translateX(10%)']
          }, {
            direction: 'normal',
            duration: 100,
            iterations: 1,
            easing: 'linear',
          })
        }
      }
    }
  },
  components: {
    GameModal,
    SponsorLogo,
  },
  watch: {
    prizeSponsorId(newSponsorId) {
      this.fetchPrizesForSponsor(newSponsorId)
    },
  }
}
</script>
